export default {
  labels: {
    taskStates: 'Besichtigungsstatus',
    newAssignments: 'Neue Besichtigungen',
    completeAssignments: 'Abgeschlossene Besichtigungen',
    createdAssignments: 'Neue Besichtigungen',
    assignments: 'Besichtigungen',
  },
  assignments: {
    labels: {
      notAcceptedTasks: 'Nicht geplant',
    },
    notAcceptedTasks: {
      noRecords: {
        title: 'Keine Besichtigungen vorhanden',
        text: 'Es gibt gerade keine nicht geplantem Besichtigungen.',
      },
    },
    acceptedTasks: {
      noRecords: {
        title: 'Keine Besichtigungen vorhanden',
        text: 'Es gibt gerade keine nicht terminierten Besichtigungen.',
      },
    },
  },
  metrics: {
    workloadForDashboard: {
      title: 'Auslastung pro Besichtiger',
      daily_new_tasks_per_owner: 'Neue Besichtigungen',
      available_owners: 'Verfügbare Besichtiger',
    },
    notAcceptedTasksForDashboard: {
      title: 'Nicht geplant',
    },
  },
  gdfTaskErrors: {
    taskLabelWithId: 'Besichtigung: {{taskId}}',
    openTaskFiles: 'Zu den Dateien der Besichtigung',
  },
  emailTaskErrors: {
    taskAndDate: 'Auftrag: {{taskId}} ({{date}})',
    openTaskEmails: 'Zu den E-Mails der Besichtigung',
  },
};
