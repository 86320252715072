export default {
  labels: {
    newAssignments: 'New Tasks',
    greeting: 'Welcome',
    today: 'Today',
    yesterday: 'Yesterday',
    weeklyAvarage: 'Ø week',
    monthlyAvarage: 'Ø 4 weeks',
    tasks: 'Tasks',
    all: 'All',
    toDo: 'To do',
    done: 'Done',
    completeAssignments: 'Completed assignments',
    assignments: 'Assignments',
    openSubTasks: 'Open tasks',
    subTasksToReview: 'Tasks to review',
    dailyCalendar: 'Agenda',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
    dueToday: 'Due Today',
    taskStates: 'Order status',
    createdAssignments: 'New orders',
    allLeaders: 'All team leaders',
    interfaceErrors: 'Interface error',
    navigateTo: 'Navigate to',
  },
  greeting: {
    title: 'Welcome back, {{name}}',
    description: 'Here you will find useful information about your current tasks',
  },
  assignments: {
    labels: {
      notAcceptedTasks: 'Not accepted',
      acceptedTasks: 'Not scheduled',
    },
    notAcceptedTasks: {
      noRecords: {
        title: 'No tasks available',
        text: 'There are no unaccepted tasks right now.',
      },
    },
    acceptedTasks: {
      noRecords: {
        title: 'No tasks available',
        text: 'There are no non-scheduled tasks right now.',
      },
    },
  },
  subTasks: {
    noRecords: {
      title: 'No tasks available',
      text: 'There are no open tasks right now.',
    },
    due: 'Due on',
    assignedTo: 'Responsible person:',
  },
  metrics: {
    workloadForDashboard: {
      title: 'Utilization per regulator',
      daily_new_tasks_per_owner: 'New orders',
      in_work_average_per_owner: 'In progress',
      available_owners: 'Available regulators',
    },
    notAcceptedTasksForDashboard: {
      title: 'Not accepted',
      two_days_not_accepted: '2 days old',
      three_days_not_accepted: '3 days old',
      older_three_days_not_accepted: 'Older than 3 days',
    },
  },
};
