import { NumberFormatOptions, useInternationalization } from '@progress/kendo-react-intl';
import { useCallback } from 'react';

export const useFormatPercentage = () => {
  const intl = useInternationalization();

  return useCallback(
    (
      value: number,
      formatOptions?: Pick<NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>,
    ) =>
      intl.formatNumber(value, {
        ...formatOptions,
        style: 'percent',
      }),
    [intl],
  );
};
