export default {
  labels: {
    client_location_type_id: 'Risikoort-Typ',
  },
  components: {
    clientMessagesTab: {
      header: {
        description:
          'Hier können Sie Nachrichten-Vorlagen auswählen, welche dann in Besichtigungen mit nur 2 Klicks ausgesendet werden können.',
      },
    },
    serviceLevelTab: {
      blocks: {
        process: {
          acceptTask: 'Besichtigung muss nach Zuweisung geplant werden in (Tagen)',
          setAppointment:
            'Wenn die Besichtigung geplant wurde, der Termin muss gesetzt werden in (Tagen)',
          setControlling:
            'Nach dem Ortstermin, muss die Besichtigung (sofern Controlling aktiv ist) in (Tagen) zum Controlling',
          sendReport: 'Bericht muss nach dem Ortstermin verschickt werden in (Tagen)',
        },
      },
    },
    clientDetailLocationsTab: {
      header: {
        title: 'Risikoorte',
        description: 'Hier können Sie die Risikoorte des Klienten bearbeiten.',
      },
    },
    clientDetailCommonTab: {
      blocks: {
        common: {
          title: 'Stammdaten',
        },
        qualifications: {
          title: 'Verwaltung',
          description: 'Hier können Sie die Verwaltung des Besichtigers bearbeiten.',
        },
      },
    },
    hiddenInsurancesTab: {
      header: {
        title: 'Gesperrte Versicherungsnehmer',
        description: 'Hier können Sie den Besichtiger für Versicherungsnehmer sperren',
      },
      blocks: {
        hiddenInsurances: {
          listLabels: {
            enabled: 'Alle Versicherungsnehmer',
            disabled: 'Gesperrte Versicherungsnehmer',
          },
        },
      },
    },
    qualificationTab: {
      header: {
        title: 'Verwaltung',
        description:
          'Konfigurieren Sie hier Einstellungen bezüglich der Verwaltung dieses Benutzers',
      },
      maxDistance: {
        title: 'Maximale Entfernung zur Besichtigung',
        description: 'Hier können Sie die maximale Entfernung zur Besichtigung einstellen.',
      },
      userSpecificBlocks: {
        canFinishTasks: 'Darf Besichtigungen selber, ohne Controlling abschließen',
      },
    },
    blocks: {
      title: 'Verwaltung',
    },
  },
  dialogs: {
    updateQualifications: {
      title: 'Verwaltung bearbeiten',
      description: 'Hier können Sie Verwaltung zuweisen.',
    },
    editClientQualifications: {
      title: 'Verwaltung bearbeiten',
      note: 'Bearbeiten Sie die Verwaltung des Stammdaten-Objekts.',
    },
  },
  pages: {
    clientOverview: {
      description: 'Hier sehen Sie eine Liste von Besichtiger, Teamleitern und Versicherungen.',
    },
    clientDetail: {
      locationsCount: 'Anzahl Risikoorte',
      locationsCountBadge: '{{counter}} Risikoorte',
      tabs: {
        locations: 'Risikoorte',
        hiddenInsurances: 'Gesperrte Versicherungsnehmer',
        qualification: 'Verwaltung',
      },
    },
  },
  notifications: {
    editClientQualifications: {
      success: 'Verwaltungen gespeichert!',
    },
    upsertClientQualificationSuccess: 'Verwaltung erfolgreich gespeichert',
    updateClientQualifications: {
      success: 'Verwaltung wurden gespeichert.',
    },
    hideInsurances: {
      success: 'Gesperrte Versicherungsnehmer wurden gespeichert.',
    },
  },
};
