export default {
  labels: {
    id: 'ID',
    title: 'Title',
    description: 'Description',
    unit: 'Unit',
    price: 'Price',
    category: 'Category',
    catalog: 'Catalog',
    import: 'Import',
    export: 'Export',
    postcodeFactor: 'Location factor',
    createCatalog: 'Create catalog',
    manageCatalogs: 'Update catalogs',
    color: 'Color',
    submit: 'Save trade',
  },
  components: {
    toolbar: 'Toolbar',
    serviceCatalogCategoryFilter: {
      title: 'Category',
      popoverTitle: 'Show only',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
    },
  },
  dialogs: {
    import: {
      title: 'Import service catalogue',
      note: 'Here you can import possible services via Excel table',
      save: 'Import',
      cancel: 'Cancel',
      success: 'Catalog was imported successfully.',
    },
    postcodeFactors: {
      edit: {
        title: 'Location factor',
        note: 'Here you can adjust the location factor',
      },
    },
    export: {
      postcodeFactors: {
        title: 'Download location factors',
        note: 'Download the exported location factors.',
      },
      catalogItems: {
        title: 'Download services',
        note: 'Download the exported services.',
      },
    },
    createCatalog: {
      title: 'Create catalog',
      note: 'Here you can create a new catalog.',
      save: 'Create catalog',
      success: 'Catalog was created successfully.',
    },
    updateCatalogItem: {
      title: 'Edit service',
      note: 'Here you can edit the service.',
      form: {
        identifier: 'Identifier',
        title: 'Title',
        description: 'Description',
        price: 'Price',
        unit: 'Unit',
        category: 'Category',
        catalog: 'Catalog',
      },
      success: 'Leistung wurde aktualisiert.',
    },
    manageCatalogs: {
      title: 'Manage catalogs',
      note: 'Here you can update the catalogs.',
    },
    updateCatalog: {
      title: 'Update catalog',
      note: 'Here you can update the catalog.',
    },
  },
  pages: {
    overview: {
      title: 'List of services',
      description: 'Here you will find a list of possible services',
    },
    postcodeFactors: {
      title: 'Location factors',
      description: 'Here you can manage location factors',
    },
    tradesStatus: {
      title: 'Trades status',
      description:
        'Here you can adjust the trades status, which can be used for grouping in new trades.',
      toolbar: {
        createTradesStatus: 'New trade',
      },
      dialog: {
        create: {
          title: 'Create trade',
          success: 'Trade successfully created',
          error: 'Unfortunately, an error occurred while creating the union',
        },
        edit: {
          title: 'Edit trade',
          success: 'Trade successfully processed',
          error: 'Unfortunately, an error occurred when saving the union',
        },
        delete: {
          title: 'Delete trade?',
          note: 'Do you really want to delete this entry?',
          success: 'Trade successfully deleted',
          error: 'Unfortunately, an error occurred when deleting the trade',
        },
      },
    },
  },
};
