import { AnyObject, LocaleObject } from 'yup';

export interface MessageWithParams<TParams extends AnyObject = AnyObject> {
  key: string;
  params: TParams;
}

const addParamsToMessage =
  (key: string) =>
  <TParams extends AnyObject>(params: TParams): MessageWithParams<TParams> => ({ key, params });

export const yupLocale: LocaleObject = {
  mixed: {
    required: 'validation.mixed.required',
    notType: 'validation.mixed.notType',
  },
  string: {
    email: 'validation.string.email',
    min: addParamsToMessage('validation.string.min'),
    max: addParamsToMessage('validation.string.max'),
    length: addParamsToMessage('validation.string.length'),
    trim: addParamsToMessage('validation.string.trim'),
  },
  number: {
    integer: 'validation.number.integer',
    min: addParamsToMessage('validation.number.min'),
    max: addParamsToMessage('validation.number.max'),
    positive: addParamsToMessage('validation.number.positive'),
  },
  array: {
    min: addParamsToMessage('validation.array.min'),
    max: addParamsToMessage('validation.array.max'),
  },
};
