import { CanStatisticSlot } from '@module/casl/abilities/StatisticSlotAbility';
import { NoPermissionPage, NotFoundPage } from '@module/public';
import { Route, Routes } from 'react-router-dom';

import { StatistcSlotsOverview } from './pages/StatisticSlotsOverview/StatisticSlotsOverview';

const StatisticSlotsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <CanStatisticSlot action="updateStatisticSlot" failure={<NoPermissionPage />}>
            <StatistcSlotsOverview />
          </CanStatisticSlot>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default StatisticSlotsRoutes;
