import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskStatisticsSlotMutationVariables = Types.Exact<{
  input: Types.UpdateTasksStatisticsSlot;
}>;

export type UpdateTaskStatisticsSlotMutation = {
  __typename?: 'Mutation';
  updateTaskStatisticsSlot: { __typename?: 'TasksStatisticsSlot'; id: string };
};

export const UpdateTaskStatisticsSlotDocument = gql`
  mutation UpdateTaskStatisticsSlot($input: UpdateTasksStatisticsSlot!) {
    updateTaskStatisticsSlot(input: $input) {
      id
    }
  }
`;

export function useUpdateTaskStatisticsSlotMutation() {
  return Urql.useMutation<
    UpdateTaskStatisticsSlotMutation,
    UpdateTaskStatisticsSlotMutationVariables
  >(UpdateTaskStatisticsSlotDocument);
}
