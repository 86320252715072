import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/fileClassificationTagFragment.generated';
import { FileLinkFragmentDoc } from './fileLinkFragment.generated';
export type TaskImageFragment = {
  __typename?: 'FileItem';
  id: string;
  name: string;
  file_name: string;
  original_file_name: string;
  order_column?: number | null;
  url: string;
  url_thumb?: string | null;
  size: number;
  url_thumb_report?: string | null;
  url_download: string;
  number_of_pages?: number | null;
  export_pages?: Array<number> | null;
  is_extracting_pages: boolean;
  permissions: Array<Types.Permission>;
  custom_properties: {
    __typename?: 'FileItemCustomProperties';
    description?: string | null;
    is_cover?: boolean | null;
    visible?: boolean | null;
  };
  classification_tags: {
    __typename?: 'FileClassificationTagList';
    data: Array<{
      __typename?: 'FileClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      type_id: Types.FileClassificationTagTypeId;
    }>;
  };
  links: Array<{
    __typename?: 'FileLink';
    file_id: string;
    linkable_type: string;
    linkable_id: string;
    file?: { __typename?: 'FileItem'; id: string; name: string } | null;
  }>;
};

export const TaskImageFragmentDoc = gql`
  fragment TaskImage on FileItem {
    id
    name
    file_name
    original_file_name
    order_column
    url
    url_thumb
    size
    url_thumb_report
    url_download
    custom_properties {
      description
      is_cover
      visible
    }
    number_of_pages
    export_pages
    is_extracting_pages
    permissions
    classification_tags {
      data {
        ...FileClassificationTag
      }
    }
    links {
      ...FileLink
    }
  }
  ${FileClassificationTagFragmentDoc}
  ${FileLinkFragmentDoc}
`;
