export default {
  labels: {
    taskStates: 'Auftragsstatus',
    newAssignments: 'Neue Aufträge',
    greeting: 'Guten Tag',
    today: 'Heute',
    yesterday: 'Gestern',
    weeklyAvarage: 'Ø Woche',
    monthlyAvarage: 'Ø 4 Wochen',
    tasks: 'Aufgaben',
    all: 'Alle',
    toDo: 'Zu erledigen',
    done: 'Erledigt',
    completeAssignments: 'Abgeschlossene Aufträge',
    createdAssignments: 'Neue Aufträge',
    assignments: 'Aufträge',
    openSubTasks: 'Offene Aufgaben',
    openSubTask: 'Zu Aufgabe wechseln',
    editSubTask: 'Aufgabe bearbeiten',
    subTasksToReview: 'Aufgaben zur Überprüfung',
    dailyCalendar: 'Agenda',
    mon: 'Mo',
    tue: 'Di',
    wed: 'Mi',
    thu: 'Do',
    fri: 'Fr',
    sat: 'Sa',
    sun: 'So',
    jan: 'Januar',
    feb: 'Februar',
    mar: 'März',
    apr: 'April',
    may: 'Mai',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'August',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'Dezember',
    dueToday: 'Heute fällig',
    allLeaders: 'Alle Teamleiter',
    interfaceErrors: 'Schnittstellen-Fehler',
    navigateTo: 'Navigieren zu',
  },
  greeting: {
    title: 'Willkommen zurück, {{name}}',
    description: 'Hier finden Sie nützliche Informationen zu aktuellen Aufgaben',
  },
  assignments: {
    labels: {
      notAcceptedTasks: 'Nicht angenommen',
      acceptedTasks: 'Nicht terminiert',
    },
    notAcceptedTasks: {
      noRecords: {
        title: 'Keine Aufträge vorhanden',
        text: 'Es gibt gerade keine nicht angenommenen Aufträge.',
      },
    },
    acceptedTasks: {
      noRecords: {
        title: 'Keine Aufträge vorhanden',
        text: 'Es gibt gerade keine nicht terminierten Aufträge.',
      },
    },
  },
  subTasks: {
    noRecords: {
      title: 'Keine Aufgaben vorhanden',
      text: 'Es gibt gerade keine offenen Aufgaben.',
    },
    assignedTo: 'Verantwortliche Person',
    due: 'Fällig am',
  },

  metrics: {
    workloadForDashboard: {
      title: 'Auslastung pro Regulierer',
      daily_new_tasks_per_owner: 'Neuaufträge',
      in_work_average_per_owner: 'In Bearbeitung',
      available_owners: 'Verfügbare Regulierer',
    },
    notAcceptedTasksForDashboard: {
      title: 'Nicht angenommen',
      two_days_not_accepted: '2 Tage alt',
      three_days_not_accepted: '3 Tage alt',
      older_three_days_not_accepted: 'Älter als 3 Tage',
    },
  },
  gdvTaskErrors: {
    title: 'Schnittstellen-Fehler',
    taskLabelWithId: 'Auftrag: {{taskId}}',
    openTaskFiles: 'Zu den Dateien des Auftrags',
    markAsRead: 'Gelesen',
    noRecords: {
      title: 'Keine Schnittstellen-Fehler vorhanden',
      text: 'Es gibt gerade keine Schnittstellen-Fehler.',
    },
  },
  emailTaskErrors: {
    title: 'E-Mail Fehler',
    taskAndDate: 'Auftrag: {{taskId}} ({{date}})',
    emailSubject: 'E-Mail Betreff:',
    openTaskEmails: 'Zu den E-Mails des Auftrags',
    markAsRead: 'Gelesen',
    noRecords: {
      title: 'Keine E-Mail-Fehler vorhanden',
      text: 'Es gibt gerade keine E-Mail-Fehler.',
    },
  },
};
