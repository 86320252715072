export default {
  labels: {
    startVideoChat: 'Pokretanje videochat-a',
    updateVideoChat: 'Videochat ažuriran',
    external_number: 'Eksterni broj',
    identifier: 'Broj zahtjeva',
    created_at: 'Kreirano u',
    updated_at: 'Izrađen u',
    policy_number: 'Br. Police',
    customer_name: 'Korisnik',
    contact_name: 'Ime korisnika',
    reserve: 'Rezerva',
    template_type: 'Tip predloška',
    template_id: 'Predložak',
    visit_type_id: 'Tip zahtjeva',
    classificationTags: 'Tags',
    fileClassificationTags: 'Tags',
    editClassificationTags: 'Tags bearbeiten',
    newFolder: 'Nova mapa',
    newFolderTemplate: 'Nova mapa predloška',
    uploadFile: 'Prenesi datoteku',
    home: 'Home',
    download: 'Preuzmi',
    state: 'Status',
    owner: 'Vlasnik',
    file_size: 'Veličina',
    mime_type: 'Tip',
    newEmail: 'Nova E-pošta',
    actions: 'Akcije',
    call: 'Poziv',
    acceptTask: 'Prihvati',
    declineTask: 'Odbij',
    email: 'Primatelj: {{email}}',
    assign: 'Dodjeli',
    changeAssignment: 'Promjena dodjele',
    editColumns: 'Uredi kolone',
    editLockColumns: 'Zaključavanje kolona',
    enableMultiselect: 'Omoguću višestruki odabir',
    disableMultiselect: 'Onemogući višestruki odabir',
    selectedTasksCount: '{{couter}} Odabrani zahtjevi',
    leader: 'Vođa tima',
    activateTask: 'Aktiviraj zahtjev',
    createAppointment: 'Kreiraj sastanak',
    id: 'Broj zahtjeva',
    route: 'Udaljenost: {{distance}}, Trajanje: {{duration}} h',
    goToAppointment: 'Skoči na sastanak',
    appointment: 'Sastanak',
    open_subtasks: 'Otvori podzahtjev',
    assignTask: 'Dodjeli zahtjev',
    assignTasks: '{{count}} Dodjeli zahtjeve',
    resetAppointment: 'Resetiraj sastanak',
    taskControlling: 'Dodjeli kontroli',
    revertControlling: 'Odustani od kontrole',
    completeTask: 'Završi zahtjev',
    notAssigned: 'Nije dodjeljen',
    insurance: 'Osiguranje',
    deleteClassificationTags: 'Izbriši oznake',
    confirmSendReport: 'Potvrdite dostavu izvješća',
    setInitialContact: 'uspostaviti prvi kontakt',
    mobile: 'Mobilni',
    client: 'Osiguranje',
    print: 'Pogledajte PDF verziju',
    standardversion: 'zadano',
    printversion: 'Verzija za ispis',
    acceptedDate: 'Prihvaćeno',
    setInitialContactDate: 'prvi kontakt',
    setOnSiteAppointmentDate: 'dogovoreno imenovanje',
    setReportSentDate: 'izvješće o otpremi',
    completionDate: 'Završeno',
    unassigned: 'Neraspoređeno',
    cancelTask: 'Otkazati',
    createTaskAppointment: 'redoslijed rasporeda',
    sendFileInterface: 'poslati putem sučelja',
    title: 'titula',
    filePreviewNotSupported: 'Pregled nije podržan za odabranu vrstu datoteke.',
    followUpDate: 'praćenje',
    editTags: 'Uredite oznake',
    revertCancelTask: 'Poništavanje otkazivanja',
    editInvoiceMetaData: 'Uredite meta podatke',
    reminderDate: 'upozorenje',
    uploadImage: 'Učitaj sliku',
    uploadImages: 'Učitaj slike',
    editReminderDates: 'Uredite sjećanja',
    hint: 'Obavijest',
    task: 'Narudžba',
    downloadAllFiles: 'Preuzmite sve datoteke',
    stateNotShownInMapView: 'Ovaj status nije podržan u prikazu karte',
    claimant: 'Tužitelj',
    revertAssignment: 'Poništi dodjelu',
    reworkDate: 'naknadna obrada',
    controllingDate: 'kontrolirati',
    invoiceDate: 'Račun',
    reworkTask: 'Ponovno obradite narudžbu',
    reworkTaskDone: 'Naknadna obrada dovršena',
    totalAttachmentSize: 'Ukupna veličina privitaka: {{size}}',
    type: 'Tip',
    totalAttachmentSizeToBig:
      'Ukupna veličina privitka: {{size}}. Pažnja, ukupna veličina privitaka je > 30 Mbajta - ne može se jamčiti uspješno slanje poruke.',
    exportStatistics: 'Statistika izvoza',
    sendEmail: 'Pošalji e-mail',
  },
  video: {
    customerSMS: 'Stvoren je video chat! Pridružite se ovdje {{link}}',
  },
  navigation: {
    sections: {
      common: 'Općenito',
      process: 'Proces',
      finish: 'Završi',
    },
  },
  components: {
    sidebar: {
      basic: 'Podaci o zahtjevu',
      customer: 'Korisnik',
      details: 'Detalji',
      show: 'Otvori',
    },
    gridColumnMenu: {
      title: 'Uređivanje kolona',
    },
    gridColumnLockMenu: {
      title: 'Zamrzni kolonu',
    },
    fileContextMenu: {
      edit_duplicate: 'Urediti',
    },
    fileSelectionMenu: {
      download: 'Preuzmi',
      email: 'Pošalji E-poštom',
      delete: 'Izbriši',
      updateTags: 'Uređivanje oznaka',
    },
    folderContextMenu: {
      rename: 'Preimenuj',
      delete: 'Izbriši',
      permissions: 'Dozvole',
    },
    folderListView: {
      name: 'Ime',
      size: 'Veličina',
      created_at: 'Kreirano',
      updated_at: 'Ažurirano',
      classification_tags: 'Tags',
      creator: 'Stvoritelj',
    },
    folderGridView: {
      empty: 'Nisu pronađene datoteke',
    },
    noRecords: {
      files: {
        title: 'Nisu pronađene datoteke',
        text:
          'Još nema datoteka koje su u redu.\n' +
          'Možete ovdje prenijeti nove datoteke s računala.',
        folder: {
          documents: 'Dokumenti',
          images: 'Slike',
        },
        uploadLockedForFolder:
          'Još nema datoteka.\n' +
          'Možete prenijeti {{folder}} pod stavkom izbornika "{{folder}}".',
      },
      images: {
        title: 'Nisu pronađene slike',
        text: 'Još nema slika u redoslijedu.\n' + 'Možete ovdje prenijeti nove slike s računala.',
      },
      emails: {
        title: 'Nisu pronađene e-poruke',
        text: 'Još nema e-pošte u narudžbi.\nMožete ovdje poslati nove e-mailove..',
      },
      documents: {
        title: 'Nisu pronađeni dokumenti',
        text:
          'U redoslijedu još nema dokumenata.\n' +
          'Možete ovdje prenijeti nove dokumente s računala.',
      },
      invoices: {
        title: 'Nisu pronađeni računi',
        text: 'Još nisu kreirani računi.\nMožete dodati nove račune ovdje',
      },
      invoice_items: {
        title: 'Nisu pronađene pozicije',
        text: '¸Još nema popisa pozicija.\nMožete dodati nove pozicije ovdje.',
      },
      reports: {
        title: 'Izvješća nisu pronađena',
        text: 'Još nema izvješća na zahtjev.\nOvdje možete kreirati nova izvješća.',
      },
    },
    taskFolderTemplatesCard: {
      title: 'Mapa predložaka',
    },
    taskReportForm: {
      caption: 'Kreiranje novog izvješća',
      description:
        'Ovdje možete stvoriti novo izvješće prema predlošku ili stvoriti pojedinačno izvješće putem gumba Dodatno.',
      report_template_id: 'Predložak izvješća',
      report_template_id_description: 'Odabir predloška izvješća',
      expanded: 'Dodatne postavke',
      createReport: 'Generiraj izvješće',
      saveAndCreateReport: 'Spremanje i generiranje izvješća',
      custom_report_settings: {
        choose_button: 'Odabrati',
        title: {
          section_title: 'Naslovnica',
          section_description: 'Ovdje možete odabrati postavke naslovnice.',
          has_cover: 'Pokaži naslovnu stranicu u izvještaju',
          title: 'Naslov izvješća',
          use_alternate_logo: 'Koristite alternativni logotip',
        },
        menu: {
          simple: 'Jednostavan',
          advanced: 'dodatne postavke',
          word: 'Preuzmite MS Word verziju',
        },
        summary: {
          section_title: 'Sažetak',
          section_description: 'Ovdje možete odabrati postavke sažetka.',
          has_summary: 'Prikaz sažetka u izvješću',
        },
        questions: {
          section_title: 'Popis pitanja',
          section_description: 'Ovdje možete odabrati postavke za popis pitanja.',
          has_questions: 'Prikaz popisa pitanja u izvješću',
          show_questions_without_answers: 'Pokaži pitanja bez odgovora',
          button_label: 'Sakrij određena pitanja',
          choose_questions_button: 'Sakrij pitanja',
          dialog: {
            label: 'Odaberite sva pitanja koja ne bi trebala biti prikazana u izvješću.',
            title: 'Sakrij pitanja',
            inputLabel: 'Naslov pitanja',
            placeholder: 'Traži pitanje...',
            selectAll: 'Sve',
          },
          questions_hide_description: 'Prikaži opis pitanja',
          questions_alternate_title: 'Alternativni naslov za popis pitanja',
        },
        images: {
          section_title: 'Fotografije',
          section_description: 'Ovdje možete odabrati postavke za fotografije.',
          has_images: 'Prikaz fotografija u izvješću',
          images_per_page: 'Broj fotografija po stranici',
          dialog_title: 'Odabir fotografija',
        },
        documents: {
          section_title: 'Dokumenti',
          section_description: 'Ovdje možete odabrati postavke za dokumente.',
          has_documents: 'Prikaz dokumenata u izvješću',
          dialog_title: 'Odabir dokumenata',
          simple_section_description:
            'Ovdje možete izravno odabrati dokumente koji bi trebali biti vidljivi',
        },
        roomplan: {
          section_title: 'Tlocrt',
          section_description: 'Ovdje možete odabrati postavke za tlocrt.',
          has_room_plan: 'Prikaz tlocrta u izvješću',
        },
        freeText: {
          section_title: 'Tekst',
          section_description: 'Ovdje možete dodati bilo koji tekst u izvješće.',
          has_freetext: 'Prikaz teksta u izvještaju',
          freetext_title: 'Naslov teksta',
          freetext_description: 'Tekst',
          freetext_on_first_page: 'Prikaži slobodan tekst na vrhu izvješća',
        },
        toc: {
          section_title: 'Sadržaj',
          section_description: 'Ovdje možete napraviti postavke za sadržaj.',
          has_toc: 'Prikaži sadržaj u izvješću',
        },
        downloadMSWord: {
          section_title: 'Preuzmite MS Word verziju',
          section_description:
            'Generira verziju za Microsoft Word iz trenutnih podataka. Oprez: Promjene unutar Word datoteke više se ne usvajaju natrag u sustav.',
          button: 'preuzimanje datoteka',
        },
        general: {
          section_title: 'općenito',
          section_description: 'Ovdje možete odabrati opće postavke izvješća',
          manual_task_id: 'Alternativni broj narudžbe',
        },
        calculations: {
          section_title: 'izračun',
          section_description: 'Ovdje možete odabrati postavke za izračun.',
          has_calc: 'Prikaži izračun u izvješću',
        },
        forms: {
          section_title: 'Oblik',
          section_description: 'Ovdje možete odabrati želite li prikazati obrazac.',
          form_ids: 'Izbor obrazaca',
        },
        additionalForms: {
          section_title: 'podobrasci',
          section_description:
            'Ovdje možete odabrati želite li prikazati i podobrasce. Također možete koristiti samo jedan podobrazac za izvješće',
          form_ids: 'Odabir podobrazaca',
        },
      },
    },
    taskReportListView: {
      title: 'Spremljena izvješća',
      createdAt: 'Kreirano:',
    },
    taskMapAdjusterPopup: {
      assignmentNote: 'Dodjela bilješki:',
      numTasksOpen: '{{numTasksOpen}} otvoreni zahtjevi',
      adjusterSelectSuccess: 'Uplatitelj je odabran',
      leader: 'vođa tima',
    },
    taskInvoiceGrid: {
      addItem: 'Dodavanje stavki',
    },
    taskMapTaskPopup: {
      assignedTo: 'Dodjeljeno: ',
      reserve: 'Rezerva: ',
      reserveValue: '{{reserve}}€',
      assignedOwner: 'Dodjeljeni korisnik',
      notAssigned: 'Nije dodjeljeno',
    },
    taskRouteListItem: {
      sendLink: 'Pošalji poveznicu!',
      linkSent: 'Poslana poveznica!',
      sendingLink: 'Slanje poveznice!',
    },
    spellChecker: {
      undo: 'Poništeno',
      suggestions: 'Prijedlozi',
      scrollToQuestion: 'pokazati pitanje',
      insert: 'Umetnuti',
      misspellingsCount: '{{mispellings}} mogućih grešaka pronađeno u {{questionCount}} unosa',
    },
    taskFileManager: {
      errors: {
        uploadDisabledFor: 'Prijenos za mapu "{{folder}}" je onemogućen.',
      },
    },
    selectServiceCatalogItemsGrid: {
      selectionCount: '{{count}} odabranih usluga',
      deselectAll: 'Ukloniti sve',
    },
    folderContent: {
      enableMultiselect: 'Omogući višestruki odabir',
      disableMultiselect: 'Onemogući višestruki odabir',
    },
    addressFromContactPicker: {
      dropdown: {
        text: 'preuzeti podatke',
        item: 'Uzmi podatke iz {{title}}',
      },
    },
    invoiceMetaDataDialog: {
      title: 'Podaci o naplati',
    },
    invoiceMetaDataForm: {
      fields: {
        identifier: {
          label: 'broj računa',
        },
        sum_net: {
          label: 'neto iznos',
        },
        sum_tax: {
          label: 'Iznos poreza',
        },
        sum_gross: {
          label: 'Ukupni iznos',
        },
      },
    },
    fileAddAttachments: {
      title: 'Dodajte priloge',
      note: 'Odaberite datoteke koje želite priložiti.',
      submit: 'Odaberite',
    },
    tasksMapSidebar: {
      nav: {
        assignment: 'dodjela',
        tasks: 'zadaci',
      },
    },
    copyTaskAbbreviation: {
      label: 'Kopiraj kraticu',
      success: 'Kratica je kopirana',
    },
  },
  operators: {
    collection: {
      whereIn: 'Where In',
    },
  },
  dialogs: {
    renameFileDialog: {
      title: 'Preimenovanje datoteke',
      note: 'Ovdje možete preimenovati datoteku.',
      name: 'Datoteke',
    },
    confirmDialog: {
      title: 'Promjena obrasca',
      note: 'Pažnja, to mijenja postojeći oblik. Ako su podaci već popunjeni, usporedit će se s novim obrascem i, ako je potrebno, preuzeti ovu operaciju nije moguće poništiti. Želite li nastaviti?',
      confirm: 'Ok',
      cancel: 'Ne',
    },
    createAppointment: {
      title: 'Kalendar',
      note: 'Ovdje možete stvoriti novi termin.',
      buttonName: 'Termin',
      appointmentTitle: 'Naslov termina {{identifier}}',
      cannotCreateAppointment: 'Nije moguće kreirati termin.',
      calendarTab: {
        description:
          'Da biste odabrali termin, jednostavno povucite termin na licu mjesta izravno u kalendar.',
      },
      manuallyTab: {
        description: 'Unesite datum posjeta.',
      },
      tabs: {
        calendar: 'Kalender',
        manually: 'Ručno',
      },
      createAppointment: 'Stvorite privatni termin',
    },
    changeTaskAssignments: {
      title: 'Promjena dodjele',
      tasks: 'Zahtjevi',
      adjuster: 'Procjenitelj',
      note: 'Odaberite novog procjenitelja za obradu zahtjeva.',
      assignedOwner: 'Dodjeljeni procjenitelj: {{owner}}',
      noOwnerAssigned: 'Nije dodjeljen procjenitelj',
      selectAdjuster: 'Odabir procjenitelja...',
      notAssignable: 'Ne može se dodijeliti',
      notAllTasksReassigned:
        'Zbog promjena tijekom njihove promocije, nisu se mogle dodijeliti sve narudžbe.',
    },
    declineTask: {
      title: 'Odbijanje zahtjeva',
      note: 'Navedite razlog odbijanja.',
    },
    taskFileUpload: {
      title: 'Prenesi datoteku',
      clearList: 'Brisanje',
    },
    taskFolderDelete: {
      title: 'Izbriši mapu',
      note: 'Izbrisati mapu?',
    },
    taskFolderRename: {
      title: 'Preimenovanje mape',
      note: 'Novi naziv mape:',
    },
    taskFolderNew: {
      title: 'Nova mapa',
      description: 'Stvorite novu mapu',
      note: 'Nova mapa:',
    },
    createTaskFolderTemplate: {
      title: 'Predložak nove mape',
      note: 'Ovdje možete stvoriti novi predložak mape.',
    },
    updateTaskFolderTemplate: {
      title: 'Uređivanje predloška mape',
      note: 'Ovdje možete urediti predložak mape.',
    },
    deleteTaskFolderTemplate: {
      title: 'Izbriši predložak mape',
      note: 'Izbrisati predložak mape?',
    },
    deleteTaskReport: {
      title: 'Izbriši izvješće',
      note: 'Jeste li sigurni daželite izbrisati izvješće?',
    },
    previewTaskReport: {
      saveAndSend: 'Spremanje i slanje e-pošte',
    },
    previewTaskCalculationReport: {
      title: 'Pregled izračuna',
      export: 'Izvoz',
      save: 'Spremi u mapu',
    },
    replaceTags: {
      title: 'Ispunite obrazac',
      confirm: 'Da',
      cancel: 'Ne',
      note: 'U ovom dokumentu pronađene su zamjenjive varijable, želite li ih sada popuniti?',
    },
    imagesField: {
      title: 'Odabir slika',
      note: 'Odaberite nekoliko slika koje su relevantne za pitanje.',
      noRecords: {
        title: 'Nema dostupnih slika.',
        text: 'Da biste odabrali slike, najprije ih morate prenijeti u mapu slika.',
      },
    },
    exportTasks: {
      title: 'Preuzimanje popisa zahtjeva',
      note: 'Preuzimanje popisa izvezenih zahtjeva',
    },
    editTaskClassificationTags: {
      title: 'Uređivanje klasifikacije zahtjeva',
      note: 'Uređivanje klasifikacije zahtjeva.',
    },
    editTaskFileClassificationTags: {
      title: 'Uređivanje klasifikacije mape',
      note: 'Uredite klasifikaciju mape.',
    },
    editDuplicateFile: {
      title: 'Uređivanje datoteke',
      note: 'Datoteka je duplicirana za uređivanje.',
    },
    compareTaskFiles: {
      title: 'Usporedba datoteke',
      note: 'Odaberite datoteku koju želite usporediti.',
      selectFileToCompare: 'Odabir datoteke',
      showComparisonIndicator: 'Pokaži indikator usporedbe',
      openServiceCatalog: 'Pokaži katalog usluga',
      closeServiceCatalog: 'Zatvori katalog usluga',
      toggleSyncScrolling: 'Sinkronizacija pomicanja',
    },
    calculation: {
      save: {
        title: 'Novi predložak kalkulacije',
        note: 'Ovdje možete spremiti kalkulaciju kao predložak',
      },
      load: {
        title: 'Odabir predloška',
        note: 'Ovdje odaberite predložak',
      },
    },
    questionHistory: {
      title: 'Promijeni povijest u "{{title}}"',
      note: 'Ovdje možete vidjeti sve promjene ovog pitanja',
      labels: {
        performedDate: 'Datum promjene',
        userName: 'Korisnik',
        oldValue: 'Početna vrijednost',
        newValue: 'Nova vrijednost',
        reset: 'Vrati na izvornu vrijednost',
        application: 'preglednik',
        mobile: 'Mobilni',
        device: 'Uređaj',
      },
      resetButton: 'Vrati odgovor',
    },
    resetAppointment: {
      title: '¸Ponovno postavljanje termina',
      note: 'Jeste li sigurni daželite resetirati termin?',
    },
    setInitialContact: {
      title: 'uspostaviti prvi kontakt',
      note: 'Ovdje možete dodati napomenu o kontaktiranju kupca.',
      description: 'anotacija',
    },
    confirmControlling: {
      title: 'Predajte nalog na pregled',
      note: 'Time se nalog prenosi dodijeljenom vođi tima. Želite li nastaviti?',
      labelConfirm: 'Da',
      labelCancel: 'Ne',
    },
    confirmRevertControlling: {
      title: 'Pošaljite narudžbu natrag uplatitelju',
      note: 'Time se nalog vraća uplatitelju. Želite li nastaviti?',
      labelConfirm: 'Da',
      labelCancel: 'Ne',
    },
    completeTask: {
      title: 'potpuni red',
      note: 'Upozorenje, ovo postavlja nalog kao dovršen. Daljnja obrada više nije moguća. Želite li nastaviti?',
    },
    drivingDialog: {
      title: 'Uredite vremena putovanja',
      note: 'Ovdje možete urediti vrijeme putovanja.',
    },
    changeTaskTemplate: {
      title: 'promijeniti oblik',
      note: 'Pažnja, mijenjate postojeći obrazac. Ako su podaci već popunjeni, usporedit će se s novim obrascem i po potrebi prihvatiti. Ova se radnja ne može poništiti. Želite li nastaviti?',
      template: 'Oblik',
      updateSuccess: 'Forma je promijenjena.',
    },
    updateTaskFolderPermissions: {
      title: 'Dopuštenja imenika',
      note: 'Dopuštenja pristupa za grupe korisnika',
    },
    sendReport: {
      title: 'Potvrdite dostavu izvješća',
      note:
        'Ovime potvrđujete da ste poslali izvješće, to će biti zabilježeno u narudžbi.\n' +
        'Želite li nastaviti?',
    },
    deleteClassificationTags: {
      title: 'Izbriši klasifikaciju',
      description: 'Želite li stvarno izbrisati klasifikaciju?',
    },
    sendFileToInterface: {
      title: 'Pošalji datoteku putem sučelja',
      note: 'Ovo označava ovu datoteku za slanje putem aktivnog sučelja. Želite li nastaviti?',
      labelConfirm: 'Da',
      labelCancel: 'Ne',
      messages: {
        success: 'Uspješno označeno!',
      },
      confirm: 'Poslati',
    },
    cancelTask: {
      title: 'Otkazati narudžbu',
      note: 'Daljnja obrada nakon otkazivanja više nije moguća. Želite li nastaviti?',
    },
    createAdditionalTaskForm: {
      title: 'Novi podobrazac',
      note: 'Stvorite još jedan podobrazac',
    },
    updateAdditionalTaskForm: {
      title: 'Uredi podobrazac',
      note: 'Uredite podobrazac',
    },
    followUpDate: {
      title: 'Konfigurirajte praćenje',
    },
    revertCancelTask: {
      title: 'Poništavanje otkazivanja',
      note: 'Otkaz će biti povučen. Želite li nastaviti?',
    },
    reminderDate: {
      title: 'Konfigurirajte podsjetnik',
    },
    deleteTaskSubTask: {
      title: 'Izbrisati zadatak?',
      note: 'Jeste li sigurni da želite izbrisati ovaj zadatak?',
    },
    selectFormToCompare: {
      title: 'Usporedite narudžbenice',
      submit: 'Usporedi',
    },
    exportTasksStatisticsCustom: {
      title: 'Statistika izvoza',
      description: 'Ovdje možete izvesti statistiku.',
      filter: {
        created_from: 'Iz',
        created_until: 'Do',
        client_ids: 'Odabrana osiguravajuća društva',
      },
      availableInsurances: 'Dostupna osiguranja',
    },
    reworkTask: {
      title: 'Ponovno obradite narudžbu',
      note: 'Jeste li sigurni da želite preraditi narudžbu?',
    },
    reworkTaskDone: {
      title: 'Kompletan susjedski posao',
      note: 'Jeste li sigurni da želite dovršiti naknadnu obradu?',
    },
    confirmResetTaskAssignment: {
      title: 'Jeste li sigurni da želite poništiti dodjelu?',
      note: 'To znači da se zadatak više nikome ne dodjeljuje. Želite li nastaviti?',
      labelConfirm: 'Da',
      labelCancel: 'Ne',
    },
    serviceLevelTimeline: {
      title: 'Razina usluge',
      description: 'Ovdje možete vidjeti važne podatke za ovu narudžbu.',
      dateLabels: {
        createdAt: 'Stvoreno dana',
        acceptedDate: 'Prihvaćeno dana',
        setInitialContactDate: 'Prvi kontakt uključen',
        setOnSiteAppointmentDate: 'Termin dogovoren',
        setReportSentDate: 'Prijavi otpremu dana',
        completionDate: 'Završeno na',
      },
    },
  },
  notifications: {
    deleteTaskReport: {
      success: 'Izvješće je obrisano.',
      error: 'Nije moguće izbrisati izvješće.',
    },
    createCalendarEvent: {
      success: 'Uspješno kreiran termin',
      error: 'Termin nije bilo moguće kreirati.',
    },
    acceptTask: {
      success: 'Zahtjev prihvaćen',
      error: 'Zahtjev nije moguće prihvatiti.',
    },
    exportTask: {
      success: 'Popis zahtjeva uspješno izvezen.',
      error: 'Nije moguće izvesti popis zahtjeva.',
    },
    updateTask: {
      success: 'Zahtjev spremljen.',
      error: 'Nije moguće spremiti zahtjev.',
    },
    updateSubTask: {
      success: 'Podzahtjev spremljen.',
      error: 'Podzahtjev nije moguće spremiti.',
    },
    updateTaskContact: {
      success: 'Kontakt je spremljen.',
      error: 'Kontakt nije moguće spremiti.',
    },
    deleteTaskContact: {
      success: 'Kontakt je izbrisan.',
      error: 'Kontakt nije moguće izbrisati.',
    },
    updateTaskLocation: {
      success: 'Lokacija je spremljena.',
      error: 'Lokaciju nije moguće spremiti.',
    },
    createTaskReport: {
      success: 'Izvješće je uspješno kreirano!',
    },
    createTaskCalculationReport: {
      success: 'Uspješno kreirana kalkulacija!',
    },
    createTaskFolderTemplate: {
      success: 'Uspješno kreirana mapa predložaka!',
    },
    updateTaskFolderTemplate: {
      success: 'Uspješno ažurirana mapa predložaka!',
    },
    deleteTaskFolderTemplate: {
      success: 'Mapa predložaka izbrisana!',
    },
    updateFile: {
      success: 'Datoteka je spremljena.',
      error: 'Nije moguće spremiti datoteku.',
    },
    addPdfTemplate: {
      success: 'Obrazac je dodan.',
      error: 'Nije moguće dodati obrazac.',
    },
    saveTaskInvoice: {
      success: 'Račun je spremljen',
      error: 'Nije moguće spremiti račun.',
    },
    sendTaskInvoice: {
      success: 'Račun je poslan.',
      error: 'Nije moguće poslati račun.',
    },
    deleteTaskImages: {
      success: 'Slike su izbrisane.',
      error: 'Nije moguće izbrisati slike.',
    },
    deleteTaskDocuments: {
      success: 'Dokumenti su izbrisani',
      error: 'Dokumente nije moguće izbrisati.',
    },
    deleteTaskFiles: {
      success: 'Datoteke su izbrisane.',
      error: 'Nije moguće izbrisati datoteke.',
    },
    createTaskFolder: {
      success: 'Stvorena je mapa.',
      error: 'Nije moguće stvoriti mapu.',
    },
    deleteTaskFolder: {
      success: 'Mapa je izbrisana.',
      error: 'Nije moguće izbrisati mapu.',
    },
    editTaskClassificationTags: {
      success: 'Spremljena klasifikacija zahtjeva!',
    },
    editTaskFileClassificationTags: {
      success: 'Spremljena klasifikacija datoteka!',
    },
    moveFileToFolder: {
      error: '{{filename}} nije moguće premjestiti u {{folder_name}}!',
      folder_type: '{{filename}} nije moguće premjestiti u {{folder_name}}!',
      same_folder: '{{filename}} već se nalazi u {{folder_name}}!',
      success: '{{filename}} več je u {{folder_name}}!',
    },
    editDuplicateFile: {
      success: '{{filename}} je dupliciran!',
    },
    createRoomPlanerItem: {
      success: 'Tlocrt je uspješno stvoren.',
      error: 'Nije moguće stvoriti tlocrt.',
    },
    updateRoomPlanerItem: {
      success: 'Tlocrt je uspješno ažuriran.',
      error: 'Tlocrt nije moguće ažurirati.',
    },
    renameFile: {
      success: 'Datoteka je preimenovana.',
    },
    updateTaskFolderPermissions: {
      success: 'Dopuštenja su spremljena.',
      error: 'Dopuštenja se ne mogu spremiti.',
    },
    createAdditionalTaskForm: {
      success: 'Obrazac je kreiran.',
    },
    updateAdditionalTaskForm: {
      success: 'Obrazac je ažuriran.',
    },
    suggestTaskInvoiceItems: {
      noSuggestions: 'Nije pronađen nijedan prijedlog.',
      success: 'Prijedlozi su uspješno usvojeni.',
    },
    duplicateTask: {
      success: 'Narudžba je duplicirana',
      error: 'Narudžba se nije mogla duplicirati.',
    },
    updateTaskFollowUpDate: {
      success: 'Ponovno slanje je spremljeno.',
    },
    updateTaskReminderDate: {
      success: 'Podsjetnik je spremljen.',
    },
    updateInvoiceMetaData: {
      success: 'Podaci o naplati su spremljeni.',
    },
    syncInvoices: {
      success: 'Faktura je uspješno sinkronizirana',
      error: 'Nažalost, došlo je do pogreške tijekom sinkronizacije.',
    },
    resetToPriorState: 'Unos je vraćen na prethodnu vrijednost.',
    resetTaskAssignment: {
      success: 'Dodjela je uspješno poništena!',
      error: 'Došlo je do pogreške prilikom resetiranja.',
    },
    forcePageBreakSuccess: 'Umetnut je prijelom retka.',
    updateTaskDocumentVisibility: {
      error: 'Došlo je do pogreške prilikom mijenjanja vidljivosti dokumenta.',
    },
  },
  mediaGrid: {
    toolbar: {
      deleteSelected: 'Očisti označeno',
      downloadAll: 'Preuzmi',
      upload: 'Učitaj',
      downloadSelected: 'Preuzimanje odabira',
    },
    columns: {
      description: 'Opis',
      visible: 'Vidljiv',
      isCover: 'isCover',
    },
    rowActions: {
      applyDescription: 'Prihvati opis',
      delete: 'Izbriši',
      rename: 'Preimenovanje',
      compare: 'Usporedite dokumente',
      download: 'preuzimanje datoteka',
    },
    dialogs: {
      applyDescription: {
        title: 'Prihvati opis',
        note: 'Opis za nekoliko slika možete koristiti ovdje. Ovo će prebrisati sve odabrane slike istim tekstom.',
        descriptionLabel: 'Opis:',
        submit: 'Prihvati opis',
      },
      delete: {
        warning: 'Jeste li sigurni da želite izbrisati ovaj dokument?',
      },
    },
    descriptionPlaceholder: 'Dodaj opis',
    rowLoader: 'Obrada učitane datoteke... Pričekajte.',
  },
  invoice: {
    title: 'Račun',
    number: 'Broj računa',
    date: 'Datum',
    state: 'Status',
    sumGross: 'Ukupno (brutto)',
    externalNumber: 'Broj zahtjeva',
    identifier: 'Identifikator',
    address: 'Adresa',
    position: 'Pozicija',
    article: 'Artikl',
    amount: 'Količina',
    unit: 'Jedinica',
    singlePrice: 'Cijena',
    amountUser: 'RL (netto)',
    amountLeader: 'TL (netto)',
    sumNet: 'Ukupno (netto)',
    tax: 'Porez',
    sum: 'Ukupno',
    payoutTo: 'Uplata primatelju',
    internalNumber: 'Broj internog naloga',
    sumPosNet: 'Ukupno (neto)',
    sumTax: 'Ukupno (PDV)',
    generate: 'Generiraj nacrt fakture',
    searchForArticle: 'Pretraži članak',
    unitPrice: 'samci',
    external_invoice_url: 'Ekstra faktura',
  },
  pages: {
    createTask: {
      title: 'Novi zahtjev',
      description: 'Ovdje možete kreirati novi zahtjev.',
      indentifier: 'Privremeni broj narudžbe:',
    },
    folderTemplates: {
      title: 'Zadana mapa',
      description: 'Ovdje možete kreirati zadanu mapu.',
    },
    videoConference: {
      title: 'Video konferencija',
      description: 'Ovdje možete imati videokonferenciju s korisnikom.',
    },
    subTasks: {
      title: 'Moji zahtejvi',
      description: 'Ovdje možete pronaći popis zahtjeva koji su vam dodijeljeni.',
    },
    subTasksAdmin: {
      title: 'Zahtjev',
      description: 'Ovdje ćete pronaći popis svih zahtjeva.',
    },
    events: {
      title: 'Kalendar',
      description:
        'Ovdje ćete pronaći sve svoje trenutne datume. Možete unijeti i privatne obveze.',
    },
    overview: {
      title: 'Moji zahtjevi',
      description: 'Ovdje ćete pronaći popis s detaljima zahtjeva.',
      list: 'Lista',
      map: 'Karta',
      numTasksFound: '{{numTasks}} zahtjeva pronađeno',
      toolbar: {
        create: 'Kreiraj redoslijed',
        filter_placeholder: 'Traži...',
        resetFilter: 'Resetiraj pretragu',
        openSidebar: 'Prikaži bočnu traku',
        closeSidebar: 'Sakrij bočnu traku',
      },
      mapSettings: {
        appointment: 'Termin',
        adjusterNames: 'Ime regulatora',
      },
    },
    common: {
      title: 'Podaci o zahtjevu',
      description:
        'Ovdje možete vidjeti sve opće podatke o zahtjevu. Potrebni podaci mogu se prilagoditi po bloku.',
      baseData: {
        title: 'Osnovni podaci',
        dialogs: {
          editBaseData: {
            title: 'Osnovni podaci',
            note: 'Ovdje možete uređivati osnovne podatke.',
          },
        },
      },
      contacts: {
        title: 'Kontakt',
        dialogs: {
          createContact: {
            title: 'Kreiraj kontakt',
            note: 'Ovdje možete kreirati više kontakata.',
          },
          editContact: {
            title: 'Uredi kontakt',
            note: 'Ovdje možete urediti kontakt.',
          },
        },
        deleteDialog: {
          title: 'Izbriši kontakt',
          warning: 'Jeste li sigurni da želite izbrisati kontakt?',
        },
      },
      location: {
        title: 'Mjesto posjeta',
        dialogs: {
          editLocation: {
            title: 'Mjesto posjeta',
            note: 'Ovdje možete urediti adresu mjesta posjeta.',
          },
        },
        labels: {
          map: 'Otvoreno planiranje rute putem Google Maps',
          tripplanner: 'planer rute',
        },
      },
      template: {
        title: 'Predložak',
        dialogs: {
          editTemplate: {
            title: 'Predložak',
            note: 'Ovdje možete odabrati predložak obrasca i prilagoditi dodjelu procjenitelja i voditelja tima.',
          },
          editForm: {
            title: 'Uredi obrazac',
            description: 'Obrazac možete urediti ovdje.',
          },
          editLeader: {
            title: 'vođa tima',
            description: 'Ovdje možete urediti voditelja tima.',
          },
          editOwner: {
            title: 'regulator',
            description: 'Ovdje možete urediti regulator.',
          },
        },
      },
      damage: {
        title: 'Šteta',
        dialogs: {
          editDamage: {
            title: 'Šteta',
            note: 'Ovdje možete obraditi štetu.',
          },
        },
      },
    },
    todos: {
      title: 'Zahtjev',
      description:
        'Ovdje ćete pronaći sve relevantno za ovaj zahtjev. Dodatne zahtjeve možete stvoriti i sami.',
    },
    form: {
      title: 'Predložak',
      description:
        'Ovdje možete pronaći obrazac izvješća za ovaj nalog. To se dinamički dodjeljuje prilikom kreiranja zahtjeva.',
    },
    statistics: {
      title: 'Statistika',
      description:
        'Ovdje ćete pronaći statistiku zahtjeva. Sadrži relevantna pitanja za izvoz u statističke podatke i može se dinamički dodijeliti samom zahtjevu.',
    },
    images: {
      title: 'Slike',
      description:
        'Ovdje možete pronaći sve slike zahtjeva. Također možete jednostavno dodati više slika u zahtjev pomoću funkcije prijenosa.',
      grid: {
        columns: {
          thumb: 'Slike',
          cover: 'Naziv slike',
        },
        toolbar: {
          exportPdf: 'Izvezi PDF',
        },
      },
      dialogs: {
        delete: {
          title: 'Izbriši sliku',
          note: 'Želite li zaista izbrisati odabrane slike?',
        },
        upload: {
          title: 'Prijenost slika',
          note: 'Ovdje prenesite više slika za svoju zahtjev. Slike se automatski svode na maksimalnu veličinu',
          cancel: 'Odustani',
          submit: 'Prihvati',
        },
        editImage: {
          cancel: 'ODustani',
          submit: 'Spremi sliku',
          confirm: {
            title: 'Spremi promjene?',
            description: 'Napravili ste promjene na slici. Želite li ih odbaciti ili spremiti?',
          },
        },
        previewImagesReport: {
          title: 'Pregled foto izvještaja',
          export: 'Izvoz',
        },
      },
      actions: {
        showAllImages: 'Prikaži sve slike u izvješću',
        hideAllImages: 'Sakrij sve slike u izvješću',
        sortAssistant: 'Pomoćnik u sortiranju',
      },
    },
    documents: {
      title: 'Dokumenti',
      description:
        'Ovdje ćete pronaći sve PDF dokumente za zahtjev. Predlošci olakšavaju dodavanje dodatnih dokumenata (kao što su ugovori o kompenzaciji) u zahtjev.',
      grid: {
        toolbar: {
          addPdfTemplate: 'Predložak',
        },
        columns: {
          thumb: 'Dokument',
          exportedPages: {
            title: 'Aktivne stranice',
            noActivePages: 'Ne',
            noActivePagesTooltip:
              'Broj stranica premašuje maksimum od {{max_export_pages}} stranica za izvješća. Odaberite stranice ručno.',
            allActivePages: 'svi',
          },
        },
        rowActions: {
          selectExportedPages: 'Postavljanje aktivnih stranica',
          selectAllPages: 'Aktivirajte sve stranice',
        },
        pageCount: 'Stranice: {{count}}',
        rowLoader: 'Obrada učitane datoteke... Pričekajte.',
      },
      dialogs: {
        delete: {
          title: 'Brisanje dokumenata',
          note: 'Želite li zaista izbrisati odabrane dokumente?',
        },
        upload: {
          title: 'Prijenos dokumenata',
          note: 'Ovdje prenesite dodatne dokumente za svoj zahtjev.',
          submit: 'Otpremanje',
        },
        tabs: {
          paperTemplate: 'Predložak papira',
          pdfTemplate: 'Predložak PDF-a',
        },
        addPdfTemplate: {
          title: 'Dodavanje predloška',
          note: 'Dodatne elemente možete dodati izravno iz predloška kao osnovu za zahtjev.',
          noRecords: {
            title: 'Još niste prenijeli PDF obrasce.',
            buttonLabel: 'Upravljanje obrascima',
          },
        },
        document: {
          submit: 'Spremi dokument',
          currentPage: 'Trenutna stranica:',
          showInReport: 'Prikaži u izvješću',
          limit: 'Odabrano za izvješće:',
        },
        exportedPages: {
          title: 'Postavi aktivne stranice',
          note: 'Ovdje možete definirati aktivne stranice pdf dokumenta.',
          pageNumber: 'Strana {{pageNumber}}',
          selectAll: 'Odaberi sve',
          deselectAll: 'odznači sve',
          fileCount: 'broj stranica:',
          selectedFiles: 'Odabrano za izvješće:',
          selectPagesInput: {
            limitExceeded: 'Previše stranica',
            notFound: 'Stranica nije pronađena',
            exampleInput: 'Primjer: 1, 3-5, 10',
            exampleInputTooltip:
              'Unos stranice s razdjelnikom ",". Specifikacija raspona s "-". Primjer: 1, 3-5, 10',
          },
        },
      },
      actions: {
        showAllDocuments: 'Prikaži sve dokumente u izvješću',
        hideAllDocuments: 'Sakrij sve dokumente u izvješću',
      },
    },
    emails: {
      title: 'E-Pošta',
      description:
        'Ovdje ćete pronaći sve e-mailove iz ovog zahtjeva. E-poštu možete slati i izravno uključenim osobama.',
    },
    invoices: {
      title: 'Račun',
      description: 'Ovdje možete pronaći sve račune  za zahtjev',
      toolbar: {
        createInvoice: 'Kreiraj račun',
      },
      dialogs: {
        createInvoice: {
          title: 'Kreiraj račun',
          generate: 'Generiraj nacrt fakture',
        },
        editInvoice: {
          title: 'Uređivanje računa - {{external_invoice_number}}',
        },
        previewInvoice: {
          title: 'Pregled računa - {{external_invoice_number}}',
          sendInvoice: 'Pošalju račun',
        },
        deleteInvoice: {
          title: 'Izbriši fakturu',
          note: 'Želite li stvarno izbrisati fakturu?',
        },
      },
      suggestArticles: 'Prethodno popunite stavke',
    },
    calculation: {
      title: 'Kalkulacija',
      description:
        'Ovdje ćete pronaći kalkulaciju za ovaj zahtjev. Usluge se ovdje mogu lako izračunati, a pregled podataka može se prikazati kao PDF.",',
    },
    files: {
      title: 'Datoteke',
      description:
        'Ovdje ćete pronaći sve datoteke i dokumente povezane s ovim zahtjevom u strukturi mape. Dodatne dokumente možete prenijeti i ovdje.',
    },
    report: {
      title: 'Izvještaj',
      description: 'Ovdje možete generirati daljnja izvješća i poslati ih uključenim osobama.',
    },
    appointment: {
      title: 'Termin',
      description:
        'Ovdje ćete pronaći sve svoje datume. Relevantni datumi za zahtjev mogu se dodati ovdje.',
    },
    roomItems: {
      title: 'Tlocrt',
      description: 'Ovdje možete izraditi tlocrt svih potrebnih soba.',
    },
    logs: {
      title: 'Log',
      description: 'Ovdje možete vidjeti sve relevantne promjene statusa za ovaj zahtjev.',
    },
    customerPortal: {
      title: 'Korisnički portal',
      description:
        'Ovdje možete pozvati druge uključene osobe u zahtjev. To mogu biti, na primjer: veze s kupcima, kao i pozivnice za videokonferenciju.',
    },
    subforms: {
      title: 'podobrasci',
      description: 'Ovdje ćete pronaći daljnje podobrasce za ovu narudžbu.',
    },
    additionalForms: {
      labels: {
        addForm: 'Novi podobrazac',
        deleteForm: 'Izbrisati',
        reorderAlphabetically: 'Poredajte po abecedi A-Z',
        duplicateForm: 'Duplikat',
      },
      dialogs: {
        delete: {
          title: 'Izbriši podobrazac',
          note: 'Jeste li sigurni da želite izbrisati odabrani podobrazac?',
        },
      },
      notifications: {
        deleteSuccess: 'Obrazac je izbrisan',
        duplicateForm: {
          success: 'Obrazac je dupliciran',
          error: 'Obrazac se nije mogao umnožiti',
        },
      },
    },
    compareTasks: {
      title: 'Usporedite narudžbenice',
      flipOrderTooltip: 'Narudžba zamjene',
    },
    listTask: {
      title: 'Tabela narudžbenica',
    },
  },
  customerPortal: {
    table: {
      addItem: 'Kreiraj sastanak',
      noRecords: {
        title: 'Nisu pronađeni unosi',
        description: 'Još nema unosa. Slobodno stvorite sastanak.',
      },
    },
  },
  roomPlan: {
    table: {
      addItem: 'Novi tlocrt',
      noRecords: {
        title: 'Nisu pronađeni unosi',
        description: 'Još nema unosa. Dobrodošli ste stvoriti tlocrt.',
      },
    },
  },
  calculation: {
    overview: {
      title: 'Kalkulacija',
      noCategory: 'NNije dodjeljen',
      sumWithoutTax: 'Ukupno bez PDV-a (Netto)',
      tax: 'PDV',
      sumWithTax: 'Ukupno s PDV-om (Brutto)',
      onlySumWithoutTax: 'Ukupno',
    },
    table: {
      title: 'Naslov i opis',
      category: 'Kategorija',
      description: 'Opis',
      unit: 'Jedinica',
      amount: 'Iznos',
      pricePerUnit: 'Cijena / Jedinica',
      timeValue: 'Vremensksa vrijednost',
      sum: 'Ukupno',
      total: 'Ukupno',
      state: 'Status',
      payoutState: 'Status isplate',
      accepted: 'Prihvaćeno',
      addItem: 'Dodaj stavku',
      addDescription: 'Dodaj opis...',
      addTitleOrService: 'Dodaj naslov ili uslugu...',
      changeDescriptionDialog: {
        title: 'Promjena opisa',
        note: 'Ovdje možete urediti opis položaja.',
        description: 'Opis',
      },
      timeValueCalculationDialog: {
        title: 'Izračunaj vremensku vrijednost',
        note: 'Ovdje možete izračunati vremensku vrijednost navođenjem nekih parametara.',
        labels: {
          apply: 'Preuzeti',
          sum: 'Zbro vremenske sume',
          maxUsageYears: 'Ukupni vijek trajana (Godine)',
          age: 'Trenutna starost (Godine)',
          deduction: 'Odbitak',
          correctionConditon: 'Korektivni faktor (%)',
          actualDeduction: 'Stvarna vrijednost',
          timeValue: 'Vremensksa vrijednost',
        },
      },
      deleteDialog: {
        title: 'Izbriši naslov?',
        message: 'Jeste li sigurni da želite izbrisati naslov?',
      },
      titleSelect: {
        title: 'Naslov',
        description: 'Opis',
        category: 'Kategorija',
        price: 'Cijena / Jedinica',
        unit: 'Jedinica',
      },
      toolbar: {
        create: 'Kreiraj kategoriju',
        editCategory: 'Uredi kategoriju',
        edit: 'Promjena tablice',
        addItem: 'Dodaj stavku',
        enableTimeValue: 'ZAktiviranje vremenske vrijednosti',
        exportPDF: 'Izvoz PDF-a',
        exportExcel: 'Izvoz Excel-a',
        exitEdit: 'Prekini uređivanje',
        insertSum: 'Prijenos iznosa',
        save: 'Spremi kao predložak',
        load: 'Učitaj iz predloška',
        enableNetCalculation: 'neto izračun',
        settings: 'Ideje',
        addCategory: 'Dodajte kategoriju',
        hideAcceptedColumn: 'Sakrij stupac "Prihvaćeno".',
      },
      noRecords: {
        title: 'Nisu pronađeni unosi',
        description: 'Još nema unosa. Slobodno unesete nove stavke.',
        descriptionWithoutCategory:
          'Još nema unosa. Da biste dodali unos, prvo morate stvoriti kategoriju. Zatim se može dodati trgovina.',
      },
      tax: 'PDV',
      ownContribution: {
        enabled: 'Osobni doprinos aktiviran',
        enable: 'Aktivirajte vlastiti doprinos',
        enableConfirm: {
          title: 'Aktivirajte vlastiti doprinos',
          description: 'Jeste li sigurni da želite aktivirati osobni doprinos za ovu poziciju?',
        },
        enableSuccess: 'Osobni doprinos aktiviran',
        disable: 'Deaktivirajte osobni doprinos',
        disableConfirm: {
          title: 'Deaktivirajte osobni doprinos',
          description: 'Jeste li sigurni da želite deaktivirati osobni doprinos za ovu poziciju?',
        },
        disableSuccess: 'Osobni doprinos deaktiviran',
      },
      resetItem: 'Postavite sve vrijednosti na 0',
    },
    dialogs: {
      labels: {
        categoryTitle: 'Naslov kategorije',
        index: 'Index',
        category: 'Kategorija',
        categoryFieldset: 'NAslov kategorije',
        colConfig: 'Kolona',
        sum: 'Ukupan naziv kolone',
        accepted: 'Prihvati naziv kolone',
        indexTooltip:
          'To vam omogućuje određivanje redoslijeda, koji je određen indeksom uzlaznim redoslijedom.',
      },
      create: {
        title: 'Kreiraj kategoriju',
      },
      modify: {
        title: 'Upravljane kategorijama',
        note: 'Ovdje možete stvarati, uređivati i brisati kategorije. Napomena: Prije stvaranja servisne usluge mora postojati barem jedna kategorija.',
        cantCreateWithoutCategory:
          'Ne možete dodati servisnu uslugu ako nema kategorija. Najprije stvorite kategoriju.',
        titleRequired: 'Naslov kategorije je obavezan',
      },
      edit: {
        title: 'Uređivanje tablice',
      },
      update: {
        title: 'Kategorija ažurirana',
      },
      createCalculationItem: {
        title: 'Dodajte trgovinu',
        note: 'Odaberite kategoriju za trgovinu.',
        category: 'kategorija',
        selectFromServiceCatalog: 'Izaberite iz kataloga',
      },
      selectFromServiceCatalog: {
        title: 'Izaberite iz kataloga',
        note: 'Odaberite usluge iz kataloga i dodajte ih izravno u izračun.',
        submit: 'Dodajte odabrane trgovine u izračun',
      },
    },
    notifications: {
      changeActiveServiceCatalog: {
        success: 'Katalog aktivnih usluga je promijenjen.',
      },
    },
  },
  forms: {
    declineTask: {
      fields: {
        reason: {
          title: 'Razlog odbijanja',
        },
        note: {
          title: 'Bilješka',
        },
      },
    },
    createTask: {
      fields: {
        client: {
          label: 'Osiguranje',
        },
        clientLocation: {
          label: 'Lokacija',
        },
        clientContact: {
          label: 'Procjenitelj',
        },
        identifier: {
          label: 'Broj zahtjeva',
        },
        parallelNumber: {
          label: 'Paralelan- / zahtjev',
        },
        externalNumber: {
          label: 'Eksterni zahtjev',
        },
        visitType: {
          label: 'Vrsta zahtjeva',
          nullOption: 'Nije navedeno',
        },
        urgent: {
          label: 'Hitno',
        },
        addContact: {
          label: 'Dodaj kontakt',
        },
        emailPrimary: {
          label: '1. E-Pošta',
        },
        emailSecondary: {
          label: '2. E-Pošta',
        },
        phonePrimary: {
          label: '1. Telefon',
        },
        phoneSecondary: {
          label: '2. Telefon',
        },
        addLocation: {
          label: 'Dodaj lokaciju',
        },
        locationType: {
          label: 'Tip',
        },
        template: {
          label: 'Predložak',
        },
        owner: {
          label: 'Procjenitelj',
          noLocationHint: 'Unesite lokaciju kako biste vidjeli sve regulatore.',
        },
        leader: {
          label: 'Vođa tima',
        },
        reserve: {
          label: 'Rezerva',
        },
        policyNumber: {
          label: 'Br. police',
        },
        damageDate: {
          label: 'Datum štete',
        },
        assignedDate: {
          label: 'Datum dodjele',
        },
        article: {
          label: 'Artikl',
        },
        activate: {
          label: 'Nalog za puštanje izravno',
        },
        description: {
          label: 'Opis',
        },
        damageNote: {
          label: 'Stvari koje treba popraviti',
        },
        qualifications: {
          label: 'Kvalifikacije',
        },
        notes: {
          label: 'Bilješke o osiguranju',
        },
        showManual: {
          active: 'Zatvori ručni unos',
          inactive: 'Otvori ručni unos',
        },
      },
      sections: {
        meta: 'Basisdaten',
        contacts: 'Kontakt',
        locations: 'Lokacija',
        template: 'Predložak',
        damage: 'Šteta',
        documents: 'Dokumenti',
        driving: 'vremena vožnje',
      },
      toggleMap: 'Karta',
      advancedLabel: 'Proširen',
      overwriteLocationLabel: 'Prepisati preko',
      submit: 'Kreiraj zahtjev',
      notifications: {
        submitSuccess: 'Zahtjev je kreiran.',
        submitError: 'Nije moguće stvoriti zahtjev.',
        requiredFields: 'Ispunite sva obavezna polja.',
        locationRequired: 'Adresa (Karta)',
        latitudeNotFound: 'Zemljopisna širina nije pronađena',
        longitudeNotFound: 'Zemljopisna dužina nije pronađena',
        openEntry: 'otvoreni nalog',
        uploadError: 'Pogreška pri učitavanju datoteka.',
      },
      hints: {
        identifierExists: 'Pažnja, broj zahtjeva koji ste odabrali već postoji.',
      },
      warnings: {
        identifierExists: {
          title: 'Broj zahtjeva već postoji',
          message:
            'Već postoji zahtjev za odabrani broj oštećenja "{{identifier}}". Želite li zaista stvoriti ovaj zahtjev s istim brojem?',
        },
        noTemplates: {
          title: 'Nema dostupnih obrazaca',
          message:
            'Nema dostupnih pitanja za izvješćivanje za osiguranje koje ste odabrali. Obratite se svom administratoru ili voditelju tima.',
          hasAbilityMessage:
            'Nema dostupnih pitanja za izvješćivanje za osiguranje koje ste odabrali. Napravite predložak za prijavu pitanja ili dodijelite odabrano osiguranje postojećem predlošku.',
          goToTemplates: 'na pitanja izvješćivanja',
        },
      },
      copyToClipboard: 'Kopirati',
      copyToClipboardSuccess: 'Kopirano',
      resetForm: 'reset obrazac',
    },
    calculation: {
      fields: {
        title: 'Naziv predloška',
        template: 'Predložak',
      },
    },
    createDrivingtime: {
      fields: {
        driveLogDistance: 'Prijeđeni kilometri',
        driveLogNote: 'Bilješke o putovanju',
        initialMileage: 'početna kilometraža',
        finalMileage: 'konačna kilometraža',
      },
      warnings: {
        finalMileage: 'Konačna kilometraža mora biti barem jednaka početnoj kilometraži',
      },
    },
  },
  fieldMenu: {
    reset: 'resetirati odgovor',
    history: 'povijest promjena',
    confirm: {
      title: 'resetirati odgovor?',
      description: 'Želite li stvarno poništiti odgovor?',
      resetNote: 'Resetirajte i bilješku',
    },
    addComment: 'Dodaj komentar',
    updateComment: 'Uredi komentar',
    deleteComment: 'Obriši komentar',
    confirmDeleteComment: {
      title: 'Obriši komentar',
      description: 'Želite li stvarno ukloniti komentar?',
      success: 'Komentar je izbrisan',
    },
    commentDialog: {
      title: 'Komentar na "{{questionTitle}}"',
      success: 'Komentar je kreiran.',
    },
    insertPageBreak: 'Umetni prijelom stranice',
    removePageBreak: 'Ukloni prijelom stranice',
    maximizable: 'Uredi u novom prozoru',
  },
  fieldComment: {
    label: 'Komentar vođe tima:',
  },
  tableField: {
    copyFromTable: 'Uzmi sa stola',
  },
};
