import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
export type ClientLocationFieldsFragment = {
  __typename?: 'ClientLocation';
  id: string;
  client_location_type_id: Types.ClientLocationTypeId;
  primary?: boolean | null;
  street_no?: string | null;
  postcode?: string | null;
  city?: string | null;
  country_id?: string | null;
  street_full?: string | null;
  street?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  notes?: string | null;
  client_id: string;
  different_policy_holder?: string | null;
  different_insurance_policy_number?: string | null;
  name?: string | null;
  country: { __typename?: 'Country'; title: string };
  client_location_type: { __typename?: 'ClientLocationType'; title: string };
};

export const ClientLocationFieldsFragmentDoc = gql`
  fragment ClientLocationFields on ClientLocation {
    id
    client_location_type_id
    primary
    street_no
    postcode
    city
    country_id
    street_full
    street
    latitude
    longitude
    notes
    client_id
    different_policy_holder
    different_insurance_policy_number
    name
    country {
      title
    }
    client_location_type {
      title
    }
  }
`;
