import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskCancelReasonsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TaskCancelReasonsQuery = {
  __typename?: 'Query';
  taskCancelReasons: Array<{ __typename?: 'TaskCancelReason'; id: string; title: string }>;
};

export const TaskCancelReasonsDocument = gql`
  query TaskCancelReasons {
    taskCancelReasons {
      id
      title
    }
  }
`;

export function useTaskCancelReasonsQuery(
  options?: Omit<Urql.UseQueryArgs<TaskCancelReasonsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskCancelReasonsQuery, TaskCancelReasonsQueryVariables>({
    query: TaskCancelReasonsDocument,
    ...options,
  });
}
