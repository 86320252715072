export default {
  labels: {
    unassigned: 'Unassigned',
    startVideoChat: 'Start video chat',
    updateVideoChat: 'Edit Video Chat',
    external_number: 'Foreign system number',
    identifier: 'Damage number',
    created_at: 'Created on',
    updated_at: 'Permitted to edit',
    policy_number: 'Insurance No.',
    customer_name: 'Customer',
    insurance: 'Insurance',
    contact_name: 'Contact person',
    reserve: 'Reserve',
    template_type: 'Claim type',
    template_id: 'Form',
    visit_type_id: 'Order type',
    classificationTags: 'Tags',
    fileClassificationTags: 'Tags',
    editClassificationTags: 'Edit tags',
    deleteClassificationTags: 'Delete tags',
    newFolder: 'New folder',
    newFolderTemplate: 'New directory template',
    uploadFile: 'Upload File',
    home: 'Home',
    download: 'Download',
    state: 'Status',
    owner: 'Regulator',
    file_size: 'Size',
    mime_type: 'type',
    newEmail: 'New email',
    actions: 'Actions',
    call: 'Call',
    acceptTask: 'Accept',
    declineTask: 'Decline',
    email: 'Email to: {{email}}',
    assign: 'Assign',
    changeAssignment: 'Change assignment',
    editColumns: 'Edit columns',
    editLockColumns: 'Lock columns',
    enableMultiselect: 'Enable multiselect',
    disableMultiselect: 'Disable multiple selection',
    selectedTasksCount: '{{couter}} Tasks selected',
    leader: 'Team leader',
    activateTask: 'Activate task',
    createAppointment: 'Set Location Appointment',
    id: 'Job number',
    route: 'Distance: {{distance}}, Travel time: {{duration}} h',
    goToAppointment: 'Jump to local appointment',
    appointment: 'Local appointment',
    open_subtasks: 'Open tasks',
    assignTask: 'Assign task',
    assignTasks: 'Assign {{count}} tasks',
    resetAppointment: 'Reset appointment',
    taskControlling: 'Set to controlling',
    revertControlling: 'Revert controlling',
    completeTask: 'Complete task',
    stateChangedDate: 'Last change of state',
    confirmSendReport: 'Confirm sending the report',
    notAssigned: 'Nicht zugewiesen',
    setInitialContact: 'Set initial contact',
    mobile: 'Mobile',
    client: 'Insurance',
    print: 'View PDF version',
    standardversion: 'Standard',
    printversion: 'Print version',
    acceptedDate: 'Accepted',
    setInitialContactDate: 'First contact',
    setOnSiteAppointmentDate: 'Appointment agreed',
    setReportSentDate: 'Report mailing',
    completionDate: 'Completed',
    cancelTask: 'Cancel',
    createTaskAppointment: 'Schedule order',
    sendFileInterface: 'Send via interface',
    editInvoiceMetaData: 'Edit meta information',
    title: 'Title',
    filePreviewNotSupported: 'Preview is not supported for the selected file type.',
    followUpDate: 'Resubmission',
    reminderDate: 'Reminder',
    editTags: 'Edit tags',
    uploadImage: 'Upload image',
    uploadImages: 'Upload images',
    sendSms: 'Send SMS',
    editReminderDates: 'Edit reminders',
    hint: 'Hint',
    revertCancelTask: 'Canceling a cancellation',
    task: 'Order',
    downloadAllFiles: 'Download all files',
    stateNotShownInMapView: 'This status is not supported in the map view',
    claimant: 'Claimant',
    revertAssignment: 'Reset assignment',
    reworkDate: 'Post-processing',
    controllingDate: 'Control',
    invoiceDate: 'Invoice',
    reworkTask: 'Reworking the order',
    reworkTaskDone: 'Post-processing completed',
    totalAttachmentSize: 'Total size of the attachments: {{size}}',
    type: 'Type',
    totalAttachmentSizeToBig:
      'Total size of the attachments: {{size}}. Attention, the total size of the attachments is > 30 Mbyte - the successful sending of the message cannot be guaranteed.',
    exportStatistics: 'Export statistics',
    sendEmail: 'Send e-mail',
  },
  video: {
    customerSMS: 'A video chat has been created! Join here {{link}}',
  },
  navigation: {
    sections: {
      common: 'General',
      process: 'Processing',
      finish: 'Completion',
    },
  },
  components: {
    taskFileManager: {
      errors: {
        uploadDisabledFor: 'Upload for folder "{{folder}}" is disabled.',
      },
    },
    selectServiceCatalogItemsGrid: {
      selectionCount: '{{count}} Services selected',
      deselectAll: 'Deselect all',
    },
    spellChecker: {
      undo: 'Undo',
      suggestions: 'Suggestions',
      scrollToQuestion: 'Show question',
      insert: 'Innsert',
      misspellingsCount:
        '{{misspellings}} potential misspellings in {{questionCount}} entries found',
    },
    sidebar: {
      basic: 'Order data',
      customer: 'Customer data',
      details: 'Details',
      show: 'Open order',
    },
    gridColumnMenu: {
      title: 'Edit column',
    },
    gridColumnLockMenu: {
      title: 'Lock columns',
    },
    fileContextMenu: {
      edit_duplicate: 'Edit',
    },
    fileSelectionMenu: {
      download: 'Download',
      email: 'Send by email',
      delete: 'Delete',
      updateTags: 'Update tags',
    },
    folderContextMenu: {
      rename: 'Rename',
      permissions: 'Permissions',
      delete: 'Delete',
    },
    folderContent: {
      enableMultiselect: 'Enable multiselect',
      disableMultiselect: 'Disable multiselect',
    },
    folderListView: {
      name: 'Name',
      size: 'Size',
      created_at: 'Created on',
      updated_at: 'allowed.edited',
      classification_tags: 'Tags',
      creator: 'Creator',
    },
    noRecords: {
      files: {
        title: 'No files found',
        text:
          'There are no files in the folder yet.\n' +
          'You are welcome to upload new files from your computer here.',
        folder: {
          documents: 'Documents',
          images: 'Images',
        },
        uploadLockedForFolder:
          'There are no files in the folder yet. \n' +
          'You can add {{folder}} in the "{{folder}}" page.',
      },
      images: {
        title: 'No images found',
        text:
          'There are no images in the order yet.\n' +
          'You are welcome to upload new images from your computer here.',
      },
      emails: {
        title: 'No emails found',
        text: 'No emails in the order yet.\nYou are welcome to send new emails here.',
      },
      documents: {
        title: 'No documents found',
        text:
          'There are no documents in the order yet.\n' +
          'You are welcome to upload new documents from your computer here.',
      },
      invoices: {
        title: 'No invoices found',
        text: 'No invoices created yet.\nYou are welcome to add new invoices here.',
      },
      invoice_items: {
        title: 'No items found',
        text: 'No items specified yet.\nYou are welcome to add new items here.',
      },
      reports: {
        title: 'No reports found',
        text:
          'There are still no reports in the order.\n' +
          'You are welcome to create new reports here.',
      },
    },
    taskFolderTemplatesCard: {
      title: 'Directory Templates',
    },
    taskReportForm: {
      caption: 'Create a new report',
      description:
        'Here you can create a new report based on a template, or use the advanced button to create a custom report',
      report_template_id: 'Report template',
      report_template_id_description: 'Select a report template',
      expanded: 'Advanced settings',
      saveAndCreateReport: 'Save and create report',
      createReport: 'Create report',
      custom_report_settings: {
        choose_button: 'Choose',
        general: {
          section_title: 'General',
          section_description: 'Here you can choose the general settings for the report',
          manual_task_id: 'Manual task id',
        },
        title: {
          section_title: 'Title page',
          section_description: 'Here you can choose the settings for the title page',
          has_cover: 'Show title page in report',
          title: 'Title of the report',
          use_alternate_logo: 'Use alternative logo',
        },
        toc: {
          section_title: 'Table of contents',
          section_description: 'Here you can choose the settings for the table of contents',
          has_toc: 'Show table of contents in report',
        },
        menu: {
          simple: 'Simple',
          advanced: 'Advanced settings',
          word: 'Download MS Word version',
        },
        summary: {
          section_title: 'Summary',
          section_description: 'Here you can choose the settings for the summary',
          has_summary: 'Show summary in report',
        },
        questions: {
          section_title: 'List of questions',
          section_description: 'Here you can select the settings for the question list',
          has_questions: 'Show question list in report',
          show_questions_without_answers: 'Show questions without answers',
          button_label: 'Hide specific questions',
          choose_questions_button: 'Hide questions',
          dialog: {
            label: 'Select all questions here that should not be displayed in the report.',
            title: 'Hide questions',
            inputLabel: 'Question title',
            placeholder: 'Search for question',
            selectAll: 'All',
          },
          question_filter: 'Show questions',
          show_all: 'All',
          show_internal_only: 'Internal',
          show_external_only: 'External',
          questions_hide_description: 'Show description for questions',
          questions_alternate_title: 'Alternative title for question list',
        },
        documents: {
          section_title: 'Documents',
          section_description: 'Here you can select the settings for documents.',
          has_documents: 'Show documents list in report',
          dialog_title: 'Select documents',
          simple_section_description:
            'Here you can directly select which documents should be visible',
        },
        images: {
          section_title: 'Photos',
          section_description: 'Here you can select the settings for the photos',
          has_images: 'Show photos in report',
          images_per_page: 'Number of photos per page',
          dialog_title: 'Select photos',
        },
        roomplan: {
          section_title: 'Room plan',
          section_description: 'Here you can select the settings for room plan.',
          has_room_plan: 'Show room plan in report',
        },
        freeText: {
          section_title: 'Free text',
          section_description: 'Here you can add any free text to the report',
          has_freetext: 'Show free text in the report',
          freetext_title: 'Title of the free text',
          freetext_description: 'Free text',
          freetext_on_first_page: 'Show free text at the first position of the report',
        },
        downloadMSWord: {
          section_title: 'Download MS Word Version',
          section_description:
            'Creates a version for Microsoft Word from the current data. Caution: Changes within the Word file are no longer adopted back into the system.',
          button: 'Download',
        },
        calculations: {
          section_title: 'Calculation',
          section_description: 'Here you can select the settings for the calculation.',
          has_calc: 'Show calculation in report',
        },
        forms: {
          section_title: 'Form',
          section_description: 'Here you can choose whether you want to display the form.',
          form_ids: 'Forms selection',
        },
        additionalForms: {
          section_title: 'Subforms',
          section_description:
            'Here you can choose whether you want to display additional subforms. You can also use only one subform for the report',
          form_ids: 'Subform selection',
        },
      },
    },
    taskReportListView: {
      title: 'Saved reports',
      createdAt: 'Created at:',
    },
    taskMapAdjusterPopup: {
      assignmentNote: 'Assignment note:',
      numTasksOpen: '{{numTasksOpen}} open tasks',
      adjusterSelectSuccess: 'Adjuster got selected',
      leader: 'Team leader',
    },
    taskInvoiceGrid: {
      addItem: 'Add item',
    },
    taskMapTaskPopup: {
      assignedTo: 'Assigned to: ',
      reserve: 'Reserve: ',
      reserveValue: '{{reserve}}€',
      assignedOwner: 'Current regulator',
      notAssigned: 'Not assigned',
    },
    taskRouteListItem: {
      sendLink: 'Send link!',
      linkSent: 'Link sent!',
      sendingLink: 'Will be sent!',
    },
    addressFromContactPicker: {
      dropdown: {
        text: 'Apply data',
        item: 'Take over data from {{title}}',
      },
    },
    invoiceMetaDataDialog: {
      title: 'Invoice data',
    },
    invoiceMetaDataForm: {
      fields: {
        identifier: {
          label: 'Invoice number',
        },
        sum_net: {
          label: 'Net amount',
        },
        sum_tax: {
          label: 'Tax amount',
        },
        sum_gross: {
          label: 'Gross amount',
        },
      },
    },
    fileAddAttachments: {
      title: 'Add attachments',
      note: 'Select the files you want to attach.',
      submit: 'Select',
    },
    tasksMapSidebar: {
      nav: {
        assignment: 'Allocation',
        tasks: 'Orders',
      },
    },
    copyTaskAbbreviation: {
      label: 'Copy abbreviation',
      success: 'Abbreviation copied',
    },
  },
  operators: {
    collection: {
      whereIn: 'Where In',
    },
  },
  dialogs: {
    changeTaskTemplate: {
      title: 'Update template',
      note: 'Attention, this will change the existing form. If data has already been filled in, it will be compared with the new form and transferred if necessary. This process cannot be undone. Do you want to continue?',
      template: 'Template',
      updateSuccess: 'Template updated successfully',
    },
    setInitialContact: {
      title: 'Set initial contact',
      note: 'Here you can set a description for the first contact with the customer.',
      description: 'Description',
    },
    renameFileDialog: {
      title: 'Rename file',
      note: 'Here you can rename the file',
      name: 'File name',
    },
    createAppointment: {
      title: 'Appointment calendar',
      note: 'You can create a new appointment here',
      buttonName: 'Location appointment',
      appointmentTitle: 'On-site appointment for order {{identifier}}',
      cannotCreateAppointment: 'Location appointment has already been scheduled.',
      calendarTab: {
        description:
          'To choose an appointment, please just drag the location appointment directly into the calendar.',
      },
      manuallyTab: {
        description: 'Please enter a date for the location appointment',
      },
      tabs: {
        calendar: 'Calendar',
        manually: 'Manual',
      },
      createAppointment: 'Create private appointment',
    },
    changeTaskAssignments: {
      title: 'Change Assignment',
      tasks: 'Assignments',
      adjuster: 'Payer',
      note: 'Select a new payer to process the assignment',
      assignedOwner: 'Current payer: {{owner}}',
      noOwnerAssigned: 'No payer assigned',
      selectAdjuster: 'Select payer...',
      notAssignable: 'Cannot be assigned',
      notAllTasksReassigned:
        'Due to changes during their action, not all orders could be assigned.',
    },
    declineTask: {
      title: 'Decline task',
      note: 'Provide a reason for the decline task',
    },
    taskFileUpload: {
      title: 'Upload File',
      clearList: 'Reset',
    },
    taskFolderDelete: {
      title: 'Delete directory',
      note: 'Delete directory?',
    },
    taskFolderRename: {
      title: 'Rename directory',
      note: 'New directory name:',
    },
    updateTaskFolderPermissions: {
      title: 'Directory Permissions',
      note: 'Access permissions for user groups',
    },
    taskFolderNew: {
      title: 'New directory',
      description: 'Create a new directory in this folder here',
      note: 'New directory name:',
    },
    createTaskFolderTemplate: {
      title: 'New directory template',
      note: 'You can create more directory templates here',
    },
    updateTaskFolderTemplate: {
      title: 'Edit directory template',
      note: 'Here you can edit the directory template',
    },
    deleteTaskFolderTemplate: {
      title: 'Delete directory template',
      note: 'Delete directory template?',
    },
    deleteTaskReport: {
      title: 'Delete report',
      note: 'Do you really want to delete the report?',
    },
    previewTaskReport: {
      saveAndSend: 'Save and send by email',
    },
    previewTaskCalculationReport: {
      title: 'Preview Calculation',
      export: 'Export',
      save: 'Save to files',
    },
    replaceTags: {
      title: 'Fill out form',
      confirm: 'Yes',
      cancel: 'No',
      note: 'Replaceable variables were found in this document, would you like to fill them in now?',
    },
    imagesField: {
      title: 'Choose images',
      note: 'Select a few images relevant to the question',
      noRecords: {
        title: 'No images available',
        text: 'In order to select images, you must first upload them to the images directory',
      },
    },
    exportTasks: {
      title: 'Download task list',
      note: 'Download the exported task list',
    },
    editTaskClassificationTags: {
      title: 'Edit task classification',
      note: 'Select the tags to change the task classification.',
    },
    editTaskFileClassificationTags: {
      title: 'Edit task file classificaiton',
      note: 'Select the tags to change the task file classification.',
    },
    editDuplicateFile: {
      title: 'Appointment calendar',
      note: 'You can create a new appointment here',
    },
    compareTaskFiles: {
      title: 'Compare files',
      note: 'Select a file you want to compare',
      selectFileToCompare: 'Select a file to compare',
      showComparisonIndicator: 'Show line helper',
      openServiceCatalog: 'Show service catalog',
      closeServiceCatalog: 'Hide service catalog',
      toggleSyncScrolling: 'Sync Scrolling',
    },
    calculation: {
      save: {
        title: 'New Calculation Template',
        note: 'Here you can save your calculation as a template',
      },
      load: {
        title: 'Select Template',
        note: 'Choose your template here',
      },
    },
    questionHistory: {
      title: 'Change history for "{{title}}"',
      note: 'Here you can see all the changes to this question',
      labels: {
        performedDate: 'Modification date',
        userName: 'From',
        oldValue: 'Origin value',
        newValue: 'New value',
        reset: 'Reset to original value',
        application: 'Browser',
        mobile: 'Mobile',
        device: 'Device',
      },
      resetButton: 'Restore answer',
    },
    resetAppointment: {
      title: 'Reset on site appointment',
      note: 'Do you really want to reset the on site appointment?',
    },
    sendReport: {
      title: 'Confirm Report Delivery',
      note:
        'This is to confirm that you have sent the report, this will be noted in the order.\n' +
        'Would you like to continue?',
    },
    deleteClassificationTags: {
      title: 'Delete tags',
      description: 'Do you really want to delete the selected tags?',
    },
    confirmControlling: {
      title: 'Hand over order for control',
      note: 'This will hand over the job to the assigned team leader. Do you want to continue?',
      labelConfirm: 'Yes',
      labelCancel: 'No',
    },
    confirmRevertControlling: {
      title: 'Transfer order back to the payer',
      note: 'This will return the job to the payer. Do you want to continue?',
      labelConfirm: 'Yes',
      labelCancel: 'No',
    },
    completeTask: {
      title: 'Complete order',
      note: 'Attention, this sets the order as completed. Further processing is no longer possible. Do you want to continue?',
    },
    drivingDialog: {
      title: 'Edit travel times',
      note: 'Here you can edit the color times.',
    },
    sendFileToInterface: {
      title: 'Send file via interface',
      note: 'This marks this file for sending via an active interface. Do you want to continue?',
      labelConfirm: 'Yes',
      labelCancel: 'No',
      messages: {
        success: 'Successfully marked!',
      },
      confirm: 'Send',
    },
    cancelTask: {
      title: 'Cancel order',
      note: 'Further processing is not possible after the cancellation. Do you want to continue?',
    },
    createAdditionalTaskForm: {
      title: 'New subform',
      note: 'Create another subform',
    },
    updateAdditionalTaskForm: {
      title: 'Edit subform title',
      note: 'Here you can change the title of the subform',
    },
    followUpDate: {
      title: 'Configure resubmission',
    },
    revertCancelTask: {
      title: 'Canceling a cancellation',
      note: 'Cancellation is canceled. Do you want to continue?',
    },
    reminderDate: {
      title: 'Configure reminder',
    },
    deleteTaskSubTask: {
      title: 'Delete task?',
      note: 'Do you really want to delete this task?',
    },
    selectFormToCompare: {
      title: 'Compare order forms',
      submit: 'Compare',
    },
    exportTasksStatisticsCustom: {
      title: 'Export statistics',
      description: 'You can export the statistics here.',
      filter: {
        created_from: 'From',
        created_until: 'Until',
        client_ids: 'Selected insurances',
      },
      availableInsurances: 'Available insurances',
    },
    reworkTask: {
      title: 'Reworking the order',
      note: 'Are you sure you want to rework the order?',
    },
    reworkTaskDone: {
      title: 'Complete neighboring work',
      note: 'Are you sure you want to complete the post-processing?',
    },
    confirmResetTaskAssignment: {
      title: 'Do you really want to reset the assignment?',
      note: 'This means that the order is no longer assigned to anyone. Do you want to continue?',
      labelConfirm: 'Yes',
      labelCancel: 'No',
    },
    serviceLevelTimeline: {
      title: 'Service level',
      description: 'Here you can see the important data for this order.',
      dateLabels: {
        createdAt: 'Created on',
        acceptedDate: 'Accepted on',
        setInitialContactDate: 'First contact on',
        setOnSiteAppointmentDate: 'Appointment made on',
        setReportSentDate: 'Report dispatch on',
        completionDate: 'Completed on',
      },
    },
    sendSms: {
      title: 'Send sms',
      note: 'You can send an SMS here. Please enter a telephone number for this.',
      send: 'Send',
      success: 'SMS will be delivered shortly',
      recipient: 'Recipient',
      message: 'Message',
    },
  },
  notifications: {
    deleteTaskReport: {
      success: 'Report was deleted',
      error: 'Report could not be deleted',
    },
    createCalendarEvent: {
      success: 'Location appointment successfully created',
      error: 'Location appointment could not be created',
    },
    acceptTask: {
      success: 'Task was accepted',
      error: 'The task could not be accepted',
    },
    updateTask: {
      success: 'Task was saved',
      error: 'Task could not be saved',
    },
    updateSubTask: {
      success: 'Todo was saved',
      error: 'Todo could not be saved',
    },
    exportTask: {
      success: 'Task list exported successfully.',
      error: 'Task list could not be exported',
    },
    updateTaskContact: {
      success: 'Contact was saved',
      error: 'Contact could not be saved',
    },
    deleteTaskContact: {
      success: 'Contact was deleted',
      error: 'Contact could not be deleted',
    },
    updateTaskLocation: {
      success: 'Location was saved',
      error: 'Location could not be saved',
    },
    createTaskReport: {
      success: 'Report successfully created!',
    },
    createTaskCalculationReport: {
      success: 'Calculation report successfully created!',
    },
    createTaskFolderTemplate: {
      success: 'Directory template created!',
    },
    updateTaskFolderTemplate: {
      success: 'Directory template updated!',
    },
    deleteTaskFolderTemplate: {
      success: 'Directory template deleted!',
    },
    updateFile: {
      success: 'File was saved',
      error: 'File could not be saved',
    },
    addPdfTemplate: {
      success: 'Form was added',
      error: 'Form could not be added',
    },
    saveTaskInvoice: {
      success: 'Invoice was saved',
      error: 'Invoice could not be saved',
    },
    sendTaskInvoice: {
      success: 'Invoice was sent',
      error: 'Invoice could not be sent',
    },
    deleteTaskImages: {
      success: 'Images were deleted',
      error: 'Images could not be deleted',
    },
    deleteTaskDocuments: {
      success: 'Documents were deleted',
      error: 'Documents could not be deleted',
    },
    deleteTaskFiles: {
      success: 'Files were deleted',
      error: 'Files could not be deleted',
    },
    createTaskFolder: {
      success: 'Directory was created',
      error: 'Directory could not be created',
    },
    deleteTaskFolder: {
      success: 'Directory was deleted',
      error: 'Directory could not be deleted',
    },
    updateTaskFolderPermissions: {
      success: 'Permissions were saved',
      error: 'Permissions could not be saved',
    },
    editTaskClassificationTags: {
      success: 'Task classification saved',
    },
    editTaskFileClassificationTags: {
      success: 'File classification saved',
    },
    moveFileToFolder: {
      error: '{{filename}} could not be moved to {{folder_name}}!',
      folder_type: '{{filename}} could not be moved to {{folder_name}}!',
      same_folder: '{{filename}} is already in {{folder_name}}!',
      success: '{{filename}} has been moved to {{folder_name}}!',
    },
    editDuplicateFile: {
      success: '{{filename}} was duplicated!',
    },
    createRoomPlanerItem: {
      success: 'Room successfully created',
      error: 'Room could not be created',
    },
    updateRoomPlanerItem: {
      success: 'Room updated successfully',
      error: 'Room could not be updated',
    },
    renameFile: {
      success: 'File renamed successfully',
    },
    createAdditionalTaskForm: {
      success: 'Form was created.',
    },
    updateAdditionalTaskForm: {
      success: 'Formular was updated.',
    },
    suggestTaskInvoiceItems: {
      noSuggestions: 'No suggestions were found.',
      success: 'Proposals were successfully adopted.',
    },
    duplicateTask: {
      success: 'Job was duplicated',
      error: 'Job could not be duplicated.',
    },
    updateTaskFollowUpDate: {
      success: 'Resubmission was saved.',
    },
    updateTaskReminderDate: {
      success: 'Reminder has been saved.',
    },
    updateInvoiceMetaData: {
      success: 'Invoice data has been saved.',
    },
    syncInvoices: {
      success: 'Invoice successfully synchronized',
      error: 'Unfortunately, an error occurred during synchronization.',
    },
    resetToPriorState: 'Input has been reset to a previous value.',
    resetTaskAssignment: {
      success: 'Assignment has been successfully reset!',
      error: 'An error occurred during the reset.',
    },
    forcePageBreakSuccess: 'Line break has been inserted.',
    updateTaskDocumentVisibility: {
      error: 'An error occurred while changing the visibility of the document.',
    },
  },
  mediaGrid: {
    toolbar: {
      deleteSelected: 'Delete selection',
      downloadAll: 'Download',
      upload: 'Upload',
      downloadSelected: 'Download selection',
    },
    columns: {
      description: 'Description',
      visible: 'Visible',
      isCover: 'Cover',
    },
    rowActions: {
      applyDescription: 'Apply description',
      delete: 'Delete',
      rename: 'Rename file',
      compare: 'Compare documents',
      download: 'Download',
    },
    dialogs: {
      applyDescription: {
        title: 'Apply Description',
        note: 'You can apply a description for several images here. This will overwrite all selected images with the same text.',
        descriptionLabel: 'Description:',
        submit: 'Apply description',
      },
      delete: {
        warning: 'Are you sure you want to delete this document?',
      },
    },
    descriptionPlaceholder: 'Add description',
    rowLoader: 'Uploaded file is being processed... Please wait.',
  },
  invoice: {
    title: 'Invoice',
    number: 'Invoice number',
    date: 'Date',
    state: 'Status',
    sumGross: 'Total (gross)',
    externalNumber: 'Claim number',
    identifier: 'Rekon GmbH number',
    address: 'Address',
    position: 'Position',
    article: 'Article',
    amount: 'Quantity',
    unit: 'Unit',
    singlePrice: 'Single',
    amountUser: 'RL (net)',
    amountLeader: 'TL (net)',
    sumNet: 'Total (net)',
    sumPosNet: 'Total (netto)',
    sumTax: 'Total (MwSt.)',
    tax: 'Tax',
    sum: 'Total',
    payoutTo: 'payment to',
    generate: 'Generate design calculation',
    internalNumber: 'Internal order number',
    searchForArticle: 'Search article',
    unitPrice: 'Single',
    external_invoice_url: 'Ext. invoice',
  },
  pages: {
    createTask: {
      title: 'New task',
      description: 'Here you can create new tasks',
      indentifier: 'Provisional order number:',
    },
    folderTemplates: {
      title: 'Default folder',
      description: 'Here you can create standard folders',
    },
    videoConference: {
      title: 'Video conference',
      description: 'Here you can hold a video conference with a client',
    },
    subTasks: {
      title: 'My tasks',
      description: 'Here you will find a list of the tasks assigned to you',
    },
    subTasksAdmin: {
      title: 'Tasks',
      description: 'Here you can find a list with all tasks.',
    },
    events: {
      title: 'Calendar',
      description:
        'Here you will find all your current appointments. You can also enter private appointments',
    },
    overview: {
      title: 'Tasks',
      description: 'Here you will find a list with detailed tasks',
      list: 'List',
      map: 'Map',
      numTasksFound: '{{numTasks}} Tasks found',
      toolbar: {
        create: 'Create task',
        filter_placeholder: 'Search...',
        resetFilter: 'Reset filter',
        openSidebar: 'Show sidebar',
        closeSidebar: 'Hide sidebar',
      },
      mapSettings: {
        appointment: 'On Site appointment',
        adjusterNames: 'Regulator name',
      },
    },
    common: {
      title: 'Order data',
      description:
        'Here you can see all the general data for the order. Necessary data can still be adjusted per block.',
      baseData: {
        title: 'Base data',
        dialogs: {
          editBaseData: {
            title: 'BaseData',
            note: 'Here you can edit the basic data',
          },
        },
      },
      contacts: {
        title: 'Contacts',
        dialogs: {
          createContact: {
            title: 'Create Contact',
            note: 'You can create more contacts here',
          },
          editContact: {
            title: 'Edit contact',
            note: 'Here you can edit the contact',
          },
        },
        deleteDialog: {
          title: 'Delete contact',
          warning: 'Do you really want to delete this contact?',
        },
      },
      location: {
        title: 'Visit location',
        dialogs: {
          editLocation: {
            title: 'Sightseeing location',
            note: 'Here you can edit the address of the inspection location',
          },
        },
        labels: {
          map: 'Open route planning via Google Maps',
          tripplanner: 'Route planner',
        },
      },
      template: {
        title: 'Form',
        dialogs: {
          editTemplate: {
            title: 'Form',
            note: 'Here you can select the form template and adjust the assignment of payer and team leader.',
          },
          editForm: {
            title: 'Edit form',
            description: 'You can edit the form here.',
          },
          editLeader: {
            title: 'Team leader',
            description: 'Here you can edit the team leader.',
          },
          editOwner: {
            title: 'Regulator',
            description: 'You can edit the regulator here.',
          },
        },
      },
      damage: {
        title: 'Damage',
        dialogs: {
          editDamage: {
            title: 'Damage',
            note: 'Here you can edit the damage',
          },
        },
      },
    },
    todos: {
      title: 'Tasks',
      description:
        'Here you will find all relevant tasks for this job. You can also create additional tasks yourself.',
    },
    form: {
      title: 'Form',
      description:
        'Here you will find the report form for this task. This is assigned dynamically when the order is created',
    },
    statistics: {
      title: 'Statistics',
      description:
        'Here you will find the statistics for the order. This contains relevant questions for an export of statistics data and can be dynamically assigned at the order itself.',
    },
    images: {
      title: 'Images',
      description:
        'Here you will find all the images for the assignment. You can also easily add more images to the order using the upload function.',
      grid: {
        columns: {
          thumb: 'image',
          cover: 'Cover',
        },
        toolbar: {
          exportPdf: 'Export PDF',
        },
      },
      dialogs: {
        delete: {
          title: 'Delete pictures',
          note: 'Do you really want to delete the selected images?',
        },
        upload: {
          title: 'Upload images',
          note: 'Upload more images for your order here. Images will automatically be resized to the maximum size',
          cancel: 'Cancel',
          submit: 'Upload',
        },
        editImage: {
          cancel: 'Cancel',
          submit: 'Save image',
          confirm: {
            title: 'Save changes?',
            description: 'You have made changes to the image. Do you want to discard or save them?',
          },
        },
        previewImagesReport: {
          title: 'Preview photo report',
          export: 'Export',
        },
      },
      actions: {
        showAllImages: 'Show all images in report',
        hideAllImages: 'Hide all images in report',
        sortAssistant: 'Sorting assistant',
      },
    },
    documents: {
      title: 'Documents',
      description:
        'Here you will find all PDF documents for the order. Using templates, you can easily add further documents (such as compensation agreements) to the order.',
      grid: {
        toolbar: {
          addPdfTemplate: 'Template',
        },
        columns: {
          thumb: 'Document',
          exportedPages: {
            exportedPages: {
              title: 'Active pages',
              noActivePages: 'None',
              noActivePagesTooltip:
                'The page count exceeds the limit of {{max_export_pages}} pages for reports. Please select pages manually.',
            },
            title: 'Active pages',
            noActivePages: 'None',
            noActivePagesTooltip:
              'Number of pages exceeds the maximum of {{max_export_pages}} Pages for reports. Please select the pages manually.',
            allActivePages: 'All',
          },
        },
        rowActions: {
          selectExportedPages: 'Set active pages',
          selectAllPages: 'Activate all pages',
        },
        pageCount: 'Number of pages: {{count}}',
        rowLoader: 'Uploaded file ist being processed... Please wait.',
      },
      dialogs: {
        delete: {
          title: 'Delete documents',
          note: 'Do you really want to delete the selected documents?',
        },
        upload: {
          title: 'Upload documents',
          note: 'Upload more documents for your order here',
          submit: 'Upload',
        },
        tabs: {
          paperTemplate: 'Paper templates',
          pdfTemplate: 'PDF forms',
        },
        addPdfTemplate: {
          title: 'Add template',
          note: 'You can add further elements as a basis for the order directly via a template here',
          noRecords: {
            title: 'You have not uploaded any PDF forms yet',
            buttonLabel: 'Manage forms',
          },
        },
        document: {
          submit: 'Save document',
          currentPage: 'Current page:',
          showInReport: 'Show in report',
          limit: 'Selected for report:',
        },
        exportedPages: {
          title: 'Set active pages',
          note: 'Here you can specify the active pages of the pdf document for use in reports. Note, there is a limit of {{max_export_pages}} pages for reports.',
          pageNumber: 'Page {{pageNumber}}',
          selectAll: 'Select all',
          deselectAll: 'Deselect all',
          fileCount: 'Number of pages:',
          selectedFiles: 'Selected for report:',
          selectPagesInput: {
            limitExceeded: 'Too many pages',
            notFound: 'Page not found',
            exampleInput: 'e.g 1, 3-5, 10',
            exampleInputTooltip:
              'Page input seperated with ",". Page range input with "-". e.g.: 1, 3-5, 10',
          },
        },
      },
      actions: {
        showAllDocuments: 'View all documents in report',
        hideAllDocuments: 'Hide all documents in the report',
      },
    },
    emails: {
      title: 'Emails',
      description:
        'Here you can find all the emails from this assignment. You can also send emails directly to people involved here.',
    },
    invoices: {
      title: 'Invoices',
      description: 'Here you can find all invoices for the order',
      toolbar: {
        createInvoice: 'Create invoice',
      },
      dialogs: {
        createInvoice: {
          title: 'Create Invoice',
          generate: 'Generate design calculation',
        },
        editInvoice: {
          title: 'Edit Invoice - {{external_invoice_number}}',
        },
        previewInvoice: {
          title: 'Invoice preview - {{external_invoice_number}}',
          sendInvoice: 'Send Invoice',
        },
        deleteInvoice: {
          title: 'Delete invoice',
          note: 'Do you really want to delete the invoice?',
        },
      },
      suggestArticles: 'Prefill article',
    },
    calculation: {
      title: 'Calculation',
      description:
        'Here you will find the calculation for this order. Trades can be easily calculated here and an overview of the data can be displayed as a PDF.',
    },
    files: {
      title: 'Files',
      description:
        'Here you will find all files and documents for this order in a folder structure. You can also upload further documents here',
    },
    report: {
      title: 'Reports',
      description: 'Here you can generate further reports and send them to involved persons.',
    },
    appointment: {
      title: 'Appointments',
      description:
        'Here you can find all your appointments. Relevant appointments for the job can be added here.',
    },
    roomItems: {
      title: 'Room overview',
      description: 'Here you can create an overview of all necessary rooms.',
    },
    logs: {
      title: 'File',
      description: 'Here you can see all relevant status changes for this order.',
    },
    customerPortal: {
      title: 'Customer portal',
      description:
        'Here you can invite other involved persons to the order. These can be e.g.: Customer links as well as invitations for a video conference',
    },
    subforms: {
      title: 'Subforms',
      description: 'Here you can find more subforms for this order.',
      notification: {
        description: 'This subform can also be edited by other people who have access to the form.',
        title: 'Editing the subform',
      },
    },
    additionalForms: {
      labels: {
        addForm: 'New subform',
        goToForm: 'Go to form',
        deleteForm: 'Delete',
        reorderAlphabetically: 'Sort alphabetically (A-Z)',
        duplicateForm: 'Duplicate',
        forms: 'Forms',
        bin: 'Bin',
      },
      dialogs: {
        delete: {
          title: 'Delete subform',
          note: 'Do you really want to delete the subform?',
        },
      },
      notifications: {
        deleteSuccess: 'Subform was deleted',
        duplicateForm: {
          success: 'Form was duplicated',
          error: 'Form could not be duplicated',
        },
      },
    },
    compareTasks: {
      title: 'Compare order forms',
      flipOrderTooltip: 'Swap order',
    },
    listTask: {
      title: 'Tabular order form',
    },
  },
  calculation: {
    overview: {
      title: 'Calculation',
      noCategory: 'Not assigned',
      sumWithoutTax: 'Total without tax (net)',
      tax: 'Total tax ({{rate}})',
      sumWithTax: 'Total sum (gross)',
      onlySumWithoutTax: 'Total',
    },
    table: {
      title: 'Title & Description',
      category: 'Category',
      description: 'Description',
      unit: 'Unit',
      amount: 'Quantity',
      pricePerUnit: 'Price / Unit',
      tax: 'Tax',
      timeValue: 'Time value',
      sum: 'Sum',
      total: 'Subtotal',
      state: 'Status',
      payoutState: 'Payment status',
      accepted: 'Accepted',
      addItem: 'Add trade',
      addDescription: 'Add description...',
      addTitleOrService: 'Add title or service...',
      changeDescriptionDialog: {
        title: 'Change description',
        note: 'Here you can edit the description of the item',
        description: 'Description',
      },
      timeValueCalculationDialog: {
        title: 'Calculate time value',
        note: 'Here you can calculate the time value by specifying some parameters',
        labels: {
          apply: 'Apply',
          sum: 'New value sum',
          maxUsageYears: 'Total useful life (years)',
          age: 'Current age (years)',
          deduction: 'Deduction',
          correctionConditon: 'Correction condition (%)',
          actualDeduction: 'Actual time value',
          timeValue: 'Time value',
        },
      },
      deleteDialog: {
        title: 'Delete row?',
        message: 'Do you really want to delete the line?',
      },
      titleSelect: {
        title: 'Title',
        description: 'Description',
        category: 'Category',
        price: 'Price / Unit',
        unit: 'Unit',
      },
      toolbar: {
        create: 'create category',
        editCategory: 'Manage categories',
        edit: 'Change table',
        addItem: 'Add trade',
        enableTimeValue: 'enable time value',
        enableNetCalculation: 'Net Calculation',
        exportPDF: 'Export PDF',
        exportExcel: 'Export Excel',
        exitEdit: 'End editing',
        insertSum: 'Insert sum',
        save: 'Save as template',
        load: 'Load from template',
        settings: 'Settings',
        addCategory: 'Add category',
        hideAcceptedColumn: 'Hide "Accepted" column',
      },
      noRecords: {
        title: 'No records found',
        description: 'There are no entries yet. You are welcome to add a trade.',
        descriptionWithoutCategory:
          'There are no entries yet. To add an entry, you must first create a category. A trade can then be added.',
      },
      ownContribution: {
        enabled: 'Own contribution activated',
        enable: 'Activate personal contribution',
        enableConfirm: {
          title: 'Activate personal contribution',
          description: 'Are you sure you want to activate the personal contribution for this item?',
        },
        enableSuccess: 'Own contribution activated',
        disable: 'Deactivate personal contribution',
        disableConfirm: {
          title: 'Deactivate personal contribution',
          description:
            'Are you sure you want to deactivate the personal contribution for this item?',
        },
        disableSuccess: 'Personal contribution deactivated',
      },
      resetItem: 'Set all values to 0',
    },
    dialogs: {
      labels: {
        categoryTitle: 'Category title',
        index: 'Index',
        category: 'Category',
        categoryFieldset: 'Category title',
        colConfig: 'column name',
        sum: 'Sum column name',
        accepted: 'Accepted column name',
        indexTooltip:
          'This allows you to determine the order, this is determined in ascending order from the index.',
      },
      create: {
        title: 'Create Category',
      },
      modify: {
        titleRequired: 'Category title is required',
        title: 'Manage categories',
        note: 'Here you can create groupings for the calculation. Also select a sorting index to sort the categories in ascending order based on these values. There has to be at least one category to be able to create a calculation.',
        cantCreateWithoutCategory:
          'You cannot add a calculation item if there are no categories. Please create a category first.',
      },
      note: 'Here you can create, edit and delete categories',
      createCalculationItem: {
        title: 'Add trade',
        note: 'Select a category for the trade',
        category: 'Category',
        selectFromServiceCatalog: 'Select from catalog',
      },
      selectFromServiceCatalog: {
        title: 'Select from catalog',
        note: 'Select services from the catalog to add them directly to the calculation',
        submit: 'Add selected services',
      },
      edit: {
        title: 'Edit table',
      },
      update: {
        title: 'Update category',
      },
    },
    edit: {
      title: 'Edit table',
    },
    update: {
      title: 'Update category',
    },
    notifications: {
      changeActiveServiceCatalog: {
        success: 'Active service catalog was changed.',
      },
    },
  },
  forms: {
    declineTask: {
      fields: {
        reason: {
          title: 'Reason for rejection',
        },
        note: {
          title: 'Note',
        },
      },
    },
    createTask: {
      fields: {
        client: {
          label: 'Insurance',
        },
        clientLocation: {
          label: 'Location',
        },
        clientContact: {
          label: 'Clerk',
        },
        identifier: {
          label: 'Claim number',
        },
        parallelNumber: {
          label: 'Parallel / subsequent order',
        },
        externalNumber: {
          label: 'External system number',
        },
        visitType: {
          label: 'Order type',
          nullOption: 'Not set',
        },
        urgent: {
          label: 'Urgent',
        },
        addContact: {
          label: 'AddContact',
        },
        emailPrimary: {
          label: '1st email',
        },
        emailSecondary: {
          label: '2nd email',
        },
        phonePrimary: {
          label: '1st phone',
        },
        phoneSecondary: {
          label: '2nd phone',
        },
        addLocation: {
          label: 'add location',
        },
        locationType: {
          label: 'Type',
        },
        template: {
          label: 'Form',
        },
        owner: {
          label: 'Payer',
          noLocationHint: 'Enter a location to see all regulators.',
        },
        leader: {
          label: 'Team leader',
        },
        reserve: {
          label: 'Reserve',
        },
        policyNumber: {
          label: 'Policy number',
        },
        damageDate: {
          label: 'Date of damage',
        },
        assignedDate: {
          label: 'Date Assignment',
        },
        article: {
          label: 'Article',
        },
        description: {
          label: 'Description',
        },
        activate: {
          label: 'Release order directly',
        },
        damageNote: {
          label: 'Things to be repaired',
        },
        qualifications: {
          label: 'Qualifications',
        },
        notes: {
          label: 'Notes on insurance',
        },
        showManual: {
          active: 'Close manual input',
          inactive: 'Open manual input',
        },
      },
      sections: {
        meta: 'Basic data',
        contacts: 'Contacts',
        locations: 'Places',
        template: 'Form',
        damage: 'Damage',
        documents: 'Documents',
        driving: 'Travel times',
      },
      toggleMap: 'Map',
      advancedLabel: 'Advanced',
      overwriteLocationLabel: 'Overwrite',
      submit: 'Create order',
      notifications: {
        submitSuccess: 'Order was created',
        submitError: 'Order could not be created',
        requiredFields: 'Please fill out all required fields',
        locationRequired: 'Address (Map)',
        latitudeNotFound: 'Latitude not found',
        longitudeNotFound: 'Longitude not found',
        openEntry: 'Open order',
        uploadError: 'Error uploading the files.',
      },
      hints: {
        identifierExists: 'Attention, the claim number you selected already exists',
      },
      warnings: {
        noTemplates: {
          title: 'No templates available',
          message:
            'There are no templates available for the selected insurance. Contact your administrator or team leader.',
          hasAbilityMessage:
            'There are no templates available for the selected insurance. Create a template for the selected insurance or assign the selected insurance to an existing template.',
          goToTemplates: 'Go to templates',
        },
        identifierExists: {
          title: 'Claim number exists',
          message:
            'A task already exists for the claim number "{{identifier}}" you selected. Do you really want to create this task with the same claim number?',
        },
      },
      copyToClipboard: 'Copy',
      copyToClipboardSuccess: 'Copied',
      resetForm: 'Reset form',
    },
    calculation: {
      fields: {
        title: 'Template name',
        template: 'Template',
      },
    },
    createDrivingtime: {
      fields: {
        driveLogDistance: 'Kilometers traveled',
        driveLogNote: 'Ride notes',
        initialMileage: 'Initial mileage',
        finalMileage: 'Final mileage',
      },
      warnings: {
        finalMileage:
          'Final odometer reading must be at least as large as the initial odometer reading',
      },
    },
  },
  customerPortal: {
    table: {
      addItem: 'Create meeting',
      noRecords: {
        title: 'No entries found',
        description: 'There are no entries yet. You are welcome to create a meeting.',
      },
    },
  },
  roomPlan: {
    table: {
      addItem: 'New room',
      noRecords: {
        title: 'No entries found',
        description: 'There are no entries yet. You are welcome to create a room.',
      },
    },
  },
  fieldMenu: {
    reset: 'Reset answer',
    history: 'Change history',
    confirm: {
      title: 'Delete note?',
      description: 'This question contains a note, should this also be deleted?',
      resetNote: 'Reset the note as well',
    },
    addComment: 'Add comment',
    updateComment: 'Edit comment',
    deleteComment: 'Delete comment',
    confirmDeleteComment: {
      title: 'Delete comment',
      description: 'Should the comment really be removed?',
      success: 'Comment was deleted',
    },
    commentDialog: {
      title: 'Comment on "{{questionTitle}}"',
      success: 'Comment was created.',
    },
    insertPageBreak: 'Insert page break',
    removePageBreak: 'Remove page break',
    maximizable: 'Edit in a new window',
  },
  fieldComment: {
    label: 'Team Leader Comment:',
  },
  tableField: {
    copyFromTable: 'Copy from table',
  },
};
