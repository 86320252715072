export default {
  labels: {
    type_id: 'Tip',
    client_location_type_id: 'Tip lokacije',
    client_location_id: 'Podružnica',
    login_possible: 'Moguća prijava na portal',
    leader_client_id: 'Vođa tima',
    bank_name: 'Banka',
    bank_code: 'BIC',
    iban: 'IBAN',
    external_identifier: 'Eksterni Id',
    account_number: 'Broj računa',
    vat_number: 'OIB',
    accounts_receivable_number: 'Debitorennummer',
    eligible_tax_deduction: 'PDV se može odbiti',
    notes: 'Bilješka',
    assignment_notes: 'Dodjela bilješke',
    location_notes: 'Bilješka o lokaciji',
    type: 'Tip',
    callClient: 'Poziv: {{phone}}',
    mailToClient: 'Email na: {{email}}',
    contacts: 'Kontakti',
    payoutAmount: 'Iznos isplate',
    payoutPercentage: 'Postotak isplate',
    leaderPayoutAmount: 'Iznos isplate vođe tima',
    leaderPayoutPercentage: 'Postotak isplate vođe tima',
    businessInsurance: 'Poslovno osiguranje',
    hasIpad: 'IPad je dostupan',
    payoutType: 'Način isplate',
    payoutTypeTax: 'Odbitak poreza',
    state_id: 'Status',
    state_notes: 'Bilješka statusa',
    payout_note: 'Bilješke o plaćanju',
    payout_note_tooltip:
      'Ova se napomena prikazuje tijekom obračuna i daje informacije o detaljnom obračunu',
    useAlternateAddress: 'Aktivirajte alternativnu adresu za naplatu',
    paymentTargetDays: 'Rok plaćanja u danima',
    hasPayout: 'Isplate su aktivne',
    damage_number_mask: 'Maska broja zahtjeva',
    term_of_payment: 'Rok plaćanja u danima',
    dateValidation: 'Datum početka mora biti prije datuma završetka',
    canSendEmails: 'Može slati e-poštu',
    different_policy_holder: 'Drugi osiguranik',
    external_number: 'Eksterni broj',
    external_client_id: 'Eksterni broj kupca',
    availableColumns: 'Dostupni stupci',
    selectedColumns: 'Odabrani stupci',
    is_absent: 'odsutan',
  },
  grids: {
    columns: {
      id: 'Id',
      type_id: 'Tip',
    },
  },
  errors: {
    not_found: {
      title: 'Klijent nije pronađen',
      note: 'Klijent kojeg ste tražili nije pronađen',
    },
  },
  notifications: {
    clientAbsenceCreate: {
      error: 'Odustnos nije mogla biti kreirana!',
      success: 'Odustnost je uspješno kreirana',
    },
    clientCreate: {
      success: 'Unos korisničkih podataka je spremljen!',
      error: 'Unos korisničkih podataka nije mogao biti spremljen!',
      validationError: 'Neuspješna provjera valjanosti!',
      openEntry: 'Otvori unos za uređivanje',
    },
    clientDelete: {
      success: 'Klijent je izbrisan!',
    },
    clientUpdate: {
      success: 'Klijent je spremljen!',
      error: 'Unos korisničkih podataka nije mogao biti spremljen!',
    },
    clientLocationCreate: {
      success: 'Lokacija kreirana!',
      validationError: 'Neuspješna provjera valjanosti!',
    },
    clientLocationDelete: {
      success: 'Podružnica je izbrisana!',
    },
    contactCreate: {
      success: 'Kontakt je spremljen!',
      validationError: 'Neuspješna provjera valjanosti!',
    },
    contactDelete: {
      success: 'Kontakt uspješno izbrisan!',
    },
    contactUpdate: {
      success: 'Kontakt uspješno ažuriran!',
    },
    upsertClientBanking: {
      success: 'Veza s bankom uspješno je spremljena!',
    },
    upsertClientTax: {
      success: 'Porezni podaci uspješno spremljeni!',
    },
    upsertClientPayroll: {
      success: 'Postavke plaćanja su uspješno spremljene!',
    },
    upsertClientQualificationSuccess: 'Kvalifikacija je uspješno spremljena',
    upsertClientServiceLevelSuccess: 'Postavke razine usluge uspješno su spremljene!',
    updateClientNotificationEmailSettings: {
      success: 'Postavka obavijesti e-poštom spremljena!',
      error: 'Postavke obavijesti putem e-pošte nije bilo moguće spremiti!',
    },
    upsertAdditionalInsuranceInput: {
      success: 'Postavke osiguranja su uspješno spremljene!',
    },
    hideInsurances: {
      success: 'Blokirana osiguranja su spremljena.',
    },
    editClientQualifications: {
      success: 'Kvalifikacije spremljene!',
    },
    clientLocationEdit: {
      success: 'Podružnica spašena!',
      validationError: 'Neuspješna provjera valjanosti!',
    },
    updateClientQualifications: {
      success: 'Kvalifikacije su spremljene.',
    },
  },
  components: {
    clientDetailCommonTab: {
      header: {
        title: 'Korisnički podaci',
        description: 'Ovdje možete urediti opće korisničke podatke klijenta.',
      },
      blocks: {
        common: {
          title: 'Osnovni podaci',
          description: 'Opći podaci o naručitelju',
          insurance_policy_number: 'Broj potvrde o osiguranju',
          customer_number: 'Broj kupca',
          customer_state_id: 'Status kupca',
        },
        state: {
          title: 'Status računa',
          description: 'Informacije o trenutnom statusu',
        },
      },
    },
    clientDetailLocationsTab: {
      header: {
        title: 'Lokacija',
        description: 'Ovdje možete urediti lokaciju klijenta.',
      },
    },
    clientDetailContactsTab: {
      header: {
        title: 'Kontakti',
        description: 'Ovdje možete urediti kontakte klijenta.',
      },
    },
    clientDetailAbsenceTab: {
      header: {
        title: 'Odsutnost',
        description: 'Ovdje možete urediti odsutnost klijenta.',
      },
    },
    clientDetailAddressTab: {
      header: {
        title: 'Adresa',
        description: 'Ovdje možete urediti podatke o adresi klijenta.',
      },
      blocks: {
        address: {
          title: 'Adresa',
          description: 'Glavna adresa klijenta',
        },
      },
    },
    clientBankingTab: {
      header: {
        title: 'Bankovni detalji',
        description: 'Ovdje možete urediti bankovne podatke klijenta.',
      },
      blocks: {
        common: {
          title: 'Bankovni detalji',
          description: 'Podaci o banci klijenta',
        },
        alternate: {
          title: 'Alternativna adresa za naplatu',
          description:
            'Aktivirajte alternativnu adresu za naplatu ako želite koristiti ovu adresu pri automatskom generiranju računa/dokumenata.',
        },
        paymentgoal: {
          title: 'rok plaćanja',
          description:
            'Ako se automatizirani računi generiraju za vrstu kupca, ovaj rok plaćanja koristit će se automatski.',
        },
      },
      validation: {
        iban: 'Ovo nije ispravan IBAN.',
      },
    },
    clientTaxationTab: {
      header: {
        title: 'Porezni podaci',
        description: 'Ovdje možete urediti porezne podatke klijenta.',
      },
      blocks: {
        common: {
          title: 'Porezni podaci',
          description: 'Porezni podaci klijenta',
        },
      },
    },
    payrollTab: {
      header: {
        title: 'Dostavnica',
        description: 'Ovdje možete napraviti sve relevantne postavke za obračun plaća zaposlenika',
      },
      blocks: {
        property: {
          title: 'Imovina',
          description: 'Ovdje ćete pronaći sve postavke za isplatu materijalne štete',
        },
        liability: {
          title: 'Odgovornost',
          description: 'Ovdje ćete pronaći sve postavke za isplatu potraživanja',
        },
        business: {
          title: 'Poslovno',
          description: 'Ovdje ćete pronaći sve postavke za isplatu komercijalne štete',
        },
        additional: {
          title: 'Napredne informacije o isplati',
          description: 'Ovdje ćete pronaći daljnje postavke za isplatu',
        },
        payout: {
          title: 'isplata',
          description: 'Ovdje možete aktivirati svoju isplatu.',
        },
        additionalInfo: {
          title: 'Proširene informacije',
          description: 'Ovdje ćete pronaći dodatne izborne opcije postavki',
        },
      },
      payoutType: {
        afterInvoice: 'nakon primitka računa',
        afterPayment: 'nakon plačanja',
      },
      payoutTypeTax: {
        gross: 'Brutto',
        net: 'Netto',
      },
    },
    serviceLevelTab: {
      header: {
        title: 'Razina usluge',
        description: 'Ovdje možete urediti razine usluge klijenta.',
      },
      blocks: {
        process: {
          title: 'Postavke procesa',
          description: 'U maksimalnom broju dana',
          acceptTask: 'Zahtjev mora biti prihvaćena (T)',
          setAppointment: 'Termin je potrebno zakazati (T)',
          setControlling: 'Nakon dogovora naručite se za kontrolu (T)',
          sendReport: 'Nakon kontrole potrebno je poslati izvješće (T)',
        },
      },
    },
    qualificationTab: {
      header: {
        title: 'Kvalifikacija',
        description: 'Ovdje možete urediti kvalifikaciju klijenta.',
      },
      userSpecificBlocks: {
        title: 'Prilagođene kvalifikacije',
        description:
          'Ovdje ćete pronaći pojedinosti o tome gdje možete prilagoditi korisnički proces',
        canFinishTasks: 'Može sam izvršavati narudžbe bez kontrole',
      },
      maxDistance: {
        title: 'Maksimalna udaljenost do posla',
        description: 'Ovdje možete postaviti maksimalnu udaljenost do narudžbe.',
        distance: 'Udaljenost u km',
      },
    },
    accountTab: {
      title: 'Korisnik - {{user_id}}',
      description:
        'Ovdje možete urediti korisničke podatke, npr. (lozinke, korisničku ulogu, ime itd.).',
      tabs: {
        common: 'Općenito',
        password: 'Lozinka',
        verify_email: 'Potvrdi E-Mail',
        role: 'Korisnička  uloga',
      },
    },
    clientMetaData: {
      createdAtDate: 'Kreirano dana',
      lastLoginData: 'Posljednji Login',
    },
    clientStateId: {
      active: 'Aktivan',
      inactive: 'Inaktivan',
      disabled: 'Blokiran',
    },
    invoicesTab: {
      header: {
        title: 'računi',
        description:
          'Ovdje možete vidjeti pregled svih faktura koje sustav generira. Fakture možete otvoriti pomoću gumba Radnje.',
      },
    },
    notification_email_settings_tab: {
      header: {
        title: 'obavijesti',
        description: 'Odaberite kako osiguravajuća društva trebaju biti informirana.',
      },
      edit: 'upravljanje postavkama',
    },
    clientInsuranceTab: {
      header: {
        title: 'Napredna',
        description: 'Ovdje možete napraviti specifične postavke za osiguranje',
      },
      blocks: {
        common: {
          title: 'općenito',
          description: 'Opće postavke',
        },
        taskExport: {
          title: 'izvoz',
          description: 'Ovdje možete odabrati stupce za izvoz i prilagoditi njihov redoslijed.',
          importStatisticExportConfigFromClient: 'Pokriveno osiguranjem',
          importStatisticExportConfigFromClientTooltip:
            'Ovdje možete preuzeti konfiguraciju drugog osiguravajućeg društva. Trenutno odabrani stupci su izgubljeni.',
          apply: 'Preuzeti',
        },
      },
    },
    hiddenInsurancesTab: {
      header: {
        title: 'Zaključana osiguranja',
        description: 'Ovdje možete zaključati uplatitelja za osiguranje',
      },
      blocks: {
        hiddenInsurances: {
          listLabels: {
            enabled: 'Sva osiguranja',
            disabled: 'Zaključana osiguranja',
          },
        },
      },
    },
  },
  dialogs: {
    clientCreate: {
      title: 'Podaci o novom klijentu',
      note: 'Unesite informacije o novom klijentu.',
    },
    clientExport: {
      title: 'Preuzmite podatke klijenta',
      note: 'Preuzmite izvezene podatke klijenta',
    },
    clientDelete: {
      title: 'Izbriši klijenta',
      note: 'Želite li stvarno izbrisati klijenta?',
    },
    clientLocationCreate: {
      title: 'Kreiraj lokaciju',
    },
    clientLocationDelete: {
      title: 'Izvriši lokaciju',
      note: 'Želite li stvarno izbrisati lokaciju?',
    },
    clientLocationEdit: {
      title: 'Uredi lokaciju',
    },
    contactCreate: {
      title: 'Kreiraj kontakt',
      note: 'Ovdje možete kreirati daljnje kontakte.',
    },
    contactEdit: {
      title: 'Uredi kontakt',
      note: 'Ovdje možete urediti podatke kontakta.',
    },
    contactDelete: {
      title: 'Izbriši kontakt',
      note: 'Želite li stvarno izbrisati kontakt?',
    },
    clientAbsenceCreate: {
      title: 'Nova odsutnost',
    },
    clientAbsenceDelete: {
      title: 'Izbriši odsutnost',
      note: 'Želite li stvarno izbrisati ovaj unos?',
    },
    clientImport: {
      title: 'Uvoz matičnih podataka',
      note: 'Ovdje možete uvesti matične podatke',
      import: 'Uvoz',
      success: 'Uspješno uvezeno.',
      error: 'Pogreška pri uvozu',
    },
    editClientEmailNotificationType: {
      title: 'Uredite obavijesti putem e-pošte',
      note: 'Ovdje možete urediti obavijesti e-poštom.',
      email_template_id: 'Predložak e-pošte',
    },
    clientLegacyImport: {
      title: 'Uvoz korisnika iz Rocketform-a',
      note: 'Korisnici se mogu uvesti iz izvoza korisnika Rocketform-a',
      import: 'Uvoz',
      success: 'Uspješno uvezeno.',
      error: 'Pogreška pri uvozu',
    },
    editClientQualifications: {
      title: 'Uredi kvalifikacije',
      note: 'Uredite kvalifikacije objekta matičnih podataka.',
    },
    updateQualifications: {
      title: 'Uredi kvalifikacije',
      description: 'Ovdje možete dodijeliti kvalifikacije.',
    },
  },
  pages: {
    clientOverview: {
      title: 'Opći podaci - pregled',
      description:
        'Ovdje možete vidjeti popis procjenitelja, voditelja timova i osiguravajućih društava.',
      toolbar: {
        create: 'Kreirati',
        import: 'Uvoz',
        importRocket: 'Uvoz korisnika iz Rocketform-a',
      },
    },
    clientDetail: {
      title: '{{name}}',
      title_name: 'Opći podaci: {{name}}',
      description: 'Ovdje možete urediti podatke kupca.',
      locationsCount: 'Broj lokacija',
      contactsCount: 'Broj kontakata',
      contactsCountBadge: '{{counter}} Kontakt',
      locationsCountBadge: '{{counter}} Lokacija',
      reason: 'Razlog',
      tabs: {
        common: 'Općenito',
        address: 'Adresa',
        contacts: 'Kontakt',
        locations: 'Lokacija',
        banking: 'Bankovni detalji',
        taxation: 'Porez',
        absence: 'Izostanci',
        payroll: 'Platni spisal',
        serviceLevel: 'Razina usluge',
        qualification: 'Kvalifikacija',
        account: 'Račun',
        notification_email_settings: 'obavijesti',
        invoices: 'računi',
        disabledForms: 'Onesposobljeni oblici',
        hiddenInsurances: 'Zaključana osiguranja',
        insurance: 'Napredna',
        policyHolder: 'kupac',
        email: 'e-pošta',
      },
      sections: {
        common: 'Općenito',
        portal: 'Pristup portalu',
      },
      forms: {
        fieldsets: {
          common: 'Općenito',
          primaryLocation: 'Adresa',
        },
      },
    },
  },
  states: {
    active: 'Aktivan',
    inactive: 'Neaktivan',
    disabled: 'Blokiran',
  },
};
