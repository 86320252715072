import { createSchemaValidator, FormGroupVertical, TextInput } from '@module/shared/forms';
import { Field } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';
import { InferType, object, string } from 'yup';

export const statisticSlotSchema = object().shape({
  title: string().max(60).required(),
  identifier: string().max(60).required(),
});

export type StatisticSlotSchema = InferType<typeof statisticSlotSchema>;

export const statisticSlotSchemaValidator = createSchemaValidator(statisticSlotSchema);

export const StatisticSlotFormFields = () => {
  const { t } = useTranslation();
  return (
    <FormGroupVertical>
      <Field name="title" label={t('statisticSlots.labels.title')} component={TextInput} />
      <Field
        name="identifier"
        label={t('statisticSlots.labels.identifier')}
        component={TextInput}
      />
    </FormGroupVertical>
  );
};
