import { GridState } from '@module/shared/graphql';
import { useMemo, useState } from 'react';

export const initialGridState: GridState = { skip: 0, take: 10 };

export function useGridStateInitial(initialGridStateOverride?: GridState) {
  const initialGridStateComputed = useMemo(() => {
    if (initialGridStateOverride) {
      return { ...initialGridState, ...initialGridStateOverride };
    }
    return initialGridState;
  }, [initialGridStateOverride]);

  return useState<GridState>(initialGridStateComputed);
}
