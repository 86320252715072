export default {
  labels: {
    type_id: 'Type',
    client_location_type_id: 'Location Type',
    client_location_id: 'Location',
    leader_client_id: 'Team leader',
    bank_name: 'Bank',
    bank_code: 'BIC',
    iban: 'IBAN',
    external_identifier: 'External Id',
    payout_note: 'Notes for Payment',
    payout_note_tooltip:
      'This note is displayed during billing and provides information on detailed billing',
    account_number: 'Account Number',
    vat_number: 'VAT ID',
    accounts_receivable_number: 'Debtor number',
    eligible_tax_deduction: 'Eligible for input tax deduction',
    notes: 'Notes',
    assignment_notes: 'Assignment note',
    location_notes: 'Notes on the location',
    type: 'Type',
    callClient: 'Call: {{phone}}',
    mailToClient: 'Email to: {{email}}',
    contacts: 'Contacts',
    payoutAmount: 'Payout amount',
    payoutPercentage: 'Payout percentage',
    leaderPayoutAmount: 'Team leader payout amount',
    leaderPayoutPercentage: 'Team leader payout percentage',
    businessInsurance: 'Business insurance',
    hasIpad: 'iPad is present',
    payoutType: 'Payout type',
    payoutTypeTax: 'Withholding tax',
    state_id: 'State',
    state_notes: 'State note',
    login_possible: 'Portal login possible',
    useAlternateAddress: 'Activate alternative billing address',
    paymentTargetDays: 'Payment term in days',
    hasPayout: 'Disbursements active',
    damage_number_mask: 'Damage number mask',
    term_of_payment: 'Payment term',
    dateValidation: 'The start date must be before the end date',
    canSendEmails: 'Can send e-mails',
    different_policy_holder: 'Alternative policyholder',
    external_number: 'External number',
    external_client_id: 'External customer number',
    availableColumns: 'Available columns',
    selectedColumns: 'Selected columns',
    is_absent: 'absent',
  },
  grids: {
    columns: {
      id: 'Id',
      type_id: 'Type',
    },
  },
  errors: {
    not_found: {
      title: 'Client not found',
      note: 'The client you requested was not found',
    },
  },
  notifications: {
    clientAbsenceCreate: {
      error: 'The absence could not be created!',
      success: 'Absence created successfully',
    },
    clientCreate: {
      success: 'Client was saved!',
      error: 'Client could not be saved!',
      validationError: 'Validation failure!',
      openEntry: 'Open entry for editing',
    },
    clientDelete: {
      success: 'Client has been deleted!',
    },
    clientUpdate: {
      success: 'Client has been saved!',
      error: 'Client could not be saved!',
    },
    clientLocationCreate: {
      success: 'Location saved!',
      validationError: 'Validation failure!',
    },
    clientLocationDelete: {
      success: 'Branch has been deleted!',
    },
    contactCreate: {
      success: 'Contact was saved!',
      validationError: 'Validation failure!',
    },
    contactDelete: {
      success: 'Contact deleted successfully!',
    },
    contactUpdate: {
      success: 'Contact has been saved!',
    },
    upsertClientBanking: {
      success: 'Bank connection saved successfully!',
    },
    upsertClientTax: {
      success: 'Tax data saved successfully!',
    },
    upsertClientPayroll: {
      success: 'Payment settings have been successfully saved!',
    },
    upsertClientServiceLevelSuccess: 'Service level settings have been successfully saved!',
    upsertClientQualificationSuccess: 'Qualification settings have been successfully saved!',
    updateClientNotificationEmailSettings: {
      success: 'Email notification setting saved!',
      error: 'Email notification setting could not be saved!',
    },
    upsertAdditionalInsuranceInput: {
      success: 'Additional insurance settings have been successfully saved!',
    },
    hideInsurances: {
      success: 'Blocked insurance policies were saved.',
    },
    editClientQualifications: {
      success: 'Qualifications saved!',
    },
    clientLocationEdit: {
      success: 'Branch office saved!',
      validationError: 'Validation failure!',
    },
    updateClientQualifications: {
      success: 'Qualifications have been saved.',
    },
  },
  components: {
    clientDetailCommonTab: {
      header: {
        title: 'General Data',
        description: 'Here you can edit the general data of the client',
      },
      blocks: {
        common: {
          title: 'Basic Information',
          description: 'Basic Information about the client',
          insurance_policy_number: 'Insurance policy number',
          customer_number: 'Customer number',
          customer_state_id: 'Customer status',
        },
        state: {
          title: 'Client State',
          description: 'Client state and reason why it may have been disabled.',
        },
      },
    },
    clientDetailLocationsTab: {
      header: {
        title: 'Branches',
        description: "Here you can edit the client's branch offices",
      },
    },
    clientDetailContactsTab: {
      header: {
        title: 'Contacts',
        description: "Here you can edit the client's contacts",
      },
    },
    clientDetailAbsenceTab: {
      header: {
        title: 'Absence',
        description: 'Here you can edit the absence of the client',
      },
    },
    clientDetailAddressTab: {
      header: {
        title: 'Address',
        description: 'Here you can edit the clients address details',
      },
      blocks: {
        address: {
          title: 'Address',
          description: 'Main address of the client',
        },
      },
    },
    clientBankingTab: {
      header: {
        title: 'Bank connection',
        description: 'Here you can edit the clients bank details',
      },
      blocks: {
        common: {
          title: 'Bank details',
          description: 'Clients bank details',
        },
        alternate: {
          title: 'Alternative billing address',
          description:
            'Activate the alternative billing address if you want to use this address when automatically generating invoices/documents.',
        },
        paymentgoal: {
          title: 'Payment term',
          description:
            'If automated invoices are created for the customer type, this payment term will be used automatically.',
        },
      },
      validation: {
        iban: 'This is not a correct IBAN.',
      },
    },
    clientInsuranceTab: {
      header: {
        title: 'Erweitert',
        description: 'Hier können Sie spezifische Einstellungen zur Versicherung vornehmen',
      },
      blocks: {
        common: {
          title: 'Allgemein',
          description: 'Allgemeine Einstellungen',
        },
        taskExport: {
          title: 'Export',
          description: 'Here you can select the columns for the export and adjust their order.',
          importStatisticExportConfigFromClient: 'Take over from insurance company',
          importStatisticExportConfigFromClientTooltip:
            'Here you can transfer a configuration from another insurance company. The currently selected columns will be lost.',
          apply: 'Take over',
        },
      },
    },
    clientTaxationTab: {
      header: {
        title: 'Tax data',
        description: 'Here you can edit the clients tax data',
      },
      blocks: {
        common: {
          title: 'Tax data',
          description: 'Clients tax data',
        },
      },
    },
    clientPayrollTab: {
      headers: {
        title: 'Payroll',
        description: 'Here you can make all relevant settings for employee payroll accounting',
      },
      blocks: {
        property: {
          title: 'Property Damage',
          description: 'Here you will find all the settings for paying out property damage',
        },
        liability: {
          title: 'Liability',
          description: 'Here you will find all the settings for paying out liability claims',
        },
        business: {
          title: 'Commercial',
          description: 'Here you will find all the settings for paying commercial claims',
        },
        additional: {
          title: 'Advanced payout information',
          description: 'Here you will find further billing settings',
        },
      },
      payoutType: {
        afterInvoice: 'after invoice',
        afterPayment: 'after payment',
      },
      payoutTypeTax: {
        gross: 'gross',
        net: 'net',
      },
    },
    serviceLevelTab: {
      headers: {
        title: 'Service Level',
        description: 'Here you can edit the clients service levels.',
      },
      blocks: {
        processes: {
          title: 'Process Settings',
          description: 'In maximum number of days',
          acceptTask: 'Task must be accepted in (T)',
          setAppointment: 'Appointment must be set in (T)',
          setControlling: 'By appointment, order for controlling in (T)',
          sendReport: 'After controlling, report must be sent in (T)',
        },
        process: {
          title: 'Process settings',
          description: 'In maximum number of days',
          acceptTask: 'Order must be accepted in (T)',
          setAppointment: 'Appointment must be set in (T)',
          setControlling: 'By date, order to be controlled in (T)',
          sendReport: 'After controlling, report must be sent in (T)',
        },
      },
      header: {
        title: 'Service level',
        description: 'Here you can edit the service levels of the client.',
      },
    },
    qualificationTab: {
      header: {
        title: 'Qualification',
        description: 'Configure settings related to the qualification of the current user.',
      },
      userSpecificBlocks: {
        title: 'User Specific Qualification',
        description: 'Here you can find details where to customize the process of the user',
        canFinishTasks: 'May complete tasks by himself, without controlling',
      },
      maxDistance: {
        title: 'Maximum distance to the job',
        description: 'Here you can set the maximum distance to the job.',
        distance: 'Distance in km',
      },
    },
    clientMetaData: {
      createdAtDate: 'Created on',
      lastLoginData: 'Last Login',
    },
    clientStateId: {
      active: 'Active',
      inactive: 'Inactive',
      disabled: 'Disabled',
    },
    payrollTab: {
      header: {
        title: 'Accounting',
        description: 'Here you can set all relevant settings for the payroll of employees',
      },
      blocks: {
        payout: {
          title: 'Payment',
          description: 'Here you can activate your payout.',
        },
        property: {
          title: 'Sach',
          description: 'Here you will find all settings for the payment of property damage',
        },
        liability: {
          title: 'Liability',
          description: 'Here you will find all the settings for the payment of liability claims',
        },
        business: {
          title: 'Trade',
          description: 'Here you will find all settings for the payment of commercial claims',
        },
        additional: {
          title: 'Advanced disbursement information',
          description: 'Here you can find more settings for billing',
        },
        additionalInfo: {
          title: 'Extended information',
          description: 'Here you will find further optional settings',
        },
      },
      payoutType: {
        afterInvoice: 'after receipt of invoice',
        afterPayment: 'After payment',
      },
      payoutTypeTax: {
        gross: 'Gross',
        net: 'Net',
      },
    },
    invoicesTab: {
      header: {
        title: 'Invoices',
        description:
          'Here you can see an overview of all invoices created by the system. You can open the invoices using the Actions button.',
      },
    },
    accountTab: {
      title: 'Users - {{user_id}}',
      description: 'Here you can edit the user data e.g.(passwords, user role, name, etc.).',
      tabs: {
        common: 'General',
        password: 'Password',
        verify_email: 'Email confirmation',
        role: 'User role',
      },
    },
    notification_email_settings_tab: {
      header: {
        title: 'Notifications',
        description: 'Choose how insurance companies should be informed.',
      },
      edit: 'Manage settings',
    },
    hiddenInsurancesTab: {
      header: {
        title: 'Locked insurances',
        description: 'Here you can block the insurance payer',
      },
      blocks: {
        hiddenInsurances: {
          listLabels: {
            enabled: 'All insurances',
            disabled: 'Locked insurances',
          },
        },
      },
    },
  },
  dialogs: {
    clientCreate: {
      title: 'New master data object',
      note: 'Specify the information about the new master data object',
    },
    clientImport: {
      title: 'Import clients Data',
      note: 'Here you can import the clients data',
      import: 'Import',
      success: 'successfully imported',
      error: 'Error occurs during import',
    },
    clientExport: {
      title: 'Download client list',
      note: 'Download the exported client list',
    },
    clientDelete: {
      title: 'Delete client',
      note: 'Do you really want to delete the client?',
    },
    clientLocationCreate: {
      title: 'Create branch',
    },
    clientLocationDelete: {
      title: 'Delete branch',
      note: 'Do you really want to delete the branch?',
    },
    clientLocationEdit: {
      title: 'Edit branch',
    },
    contactCreate: {
      title: 'Create contact',
      note: 'You can create more contacts here.',
    },
    contactEdit: {
      title: 'Edit contact',
      note: 'Here you can edit the contact data',
    },
    contactDelete: {
      title: 'Delete contact',
      note: 'Do you really want to delete the contact?',
    },
    clientAbsenceCreate: {
      title: 'New Absence',
    },
    clientAbsenceDelete: {
      title: 'Delete Absence',
      note: 'Do you really want to delete this entry?',
    },
    editClientEmailNotificationType: {
      title: 'Edit email notifications',
      note: 'Here you can edit the email notifications.',
      email_template_id: 'Email Template',
    },
    clientLegacyImport: {
      title: 'Import user from Rocketform',
      note: 'Here users can be imported from the user export of Rocketform',
      import: 'Import',
      success: 'Successfully imported.',
      error: 'Error during import',
    },
    editClientQualifications: {
      title: 'Edit qualifications',
      note: 'Edit the qualifications of the master data object.',
    },
    updateQualifications: {
      title: 'Edit qualifications',
      description: 'You can assign qualifications here.',
    },
  },
  pages: {
    clientOverview: {
      title: 'Master data - Overview',
      description: 'Here you can see a list of payers, team leaders and insurance companies',
      toolbar: {
        create: 'Create',
        import: 'import',
        importRocket: 'Import user from Rocketform',
      },
    },
    clientDetail: {
      title: '{{name}}',
      title_name: 'master_data: {{name}}',
      description: 'Here you can edit the master data of the client',
      locationsCount: 'Number of locations',
      contactsCount: 'Number of contacts',
      contactsCountBadge: '{{counter}} Contacts',
      locationsCountBadge: '{{counter}} Branches',
      reason: 'reason',
      tabs: {
        common: 'General',
        address: 'Address',
        contacts: 'Contacts',
        locations: 'Branches',
        banking: 'bank connection',
        taxation: 'taxes',
        absence: 'absences',
        payroll: 'Payroll',
        serviceLevel: 'Service level',
        qualification: 'Qualification',
        account: 'Account',
        notification_email_settings: 'Notifications',
        invoices: 'Invoices',
        disabledForms: 'Disabled forms',
        hiddenInsurances: 'Locked insurances',
        insurance: 'Extended',
        policyHolder: 'Customer',
        email: 'e-mail',
      },
      sections: {
        common: 'General',
        portal: 'Portal access',
      },
      forms: {
        fieldsets: {
          common: 'Common',
          primaryLocation: 'Address',
        },
      },
    },
  },
  states: {
    active: 'Active',
    inactive: 'Inactive',
    disabled: 'Locked',
  },
};
