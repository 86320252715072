export default {
  labels: {
    id: 'ID',
    apply: 'Apply',
    cancel: 'Cancel',
    back: 'Back',
    reset: 'Reset',
    submit: 'Submit',
    save: 'Save',
    saveCopy: 'Save as copy',
    upload: 'Upload',
    download: 'Download',
    compare: 'Compare',
    discard: 'Discard',
    saveChanges: 'Save changes',
    export: 'Export',
    create: 'Create',
    delete: 'Delete',
    duplicate: 'Duplicate',
    confirm: 'Confirm',
    open: 'Open',
    preview: 'Preview',
    select: 'Select',
    edit: 'Edit',
    rename: 'Rename',
    search: 'Search...',
    role: 'Role',
    location: 'Location',
    general: 'General',
    done: 'Done',
    title: 'Title',
    name: 'Name',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    primary_email: 'Primary Email',
    password: 'Password',
    password_confirmation: 'Password change',
    phone: 'Phone',
    phone2: 'Phone 2',
    mobile: 'Mobile',
    mobile2: 'Mobile 2',
    fax: 'Fax',
    iban: 'IBAN',
    bic: 'BIC',
    salutation_id: 'Salutation',
    street: 'Street',
    city: 'City',
    country: 'Country',
    description: 'Description',
    street_no: 'No.',
    postcode: 'Postcode',
    defaultItem: 'Please select',
    defaultItemAll: 'All',
    defaultItemAllCountries: 'All countries',
    loadingItem: 'Loading…',
    notes: 'Notes',
    bankDetails: 'Bank details',
    communication: 'Communication',
    personal: 'Personal',
    address: 'Address',
    version: 'Version',
    yes: 'Yes',
    no: 'No',
    on: 'On',
    off: 'Off',
    date: 'Date',
    time: 'Time',
    close: 'Close',
    loggedInAs: 'Logged in as',
    loginAs: 'Login as {{name}}',
    actions: 'Actions',
    view: 'View',
    from: 'From',
    to: 'To',
    new: 'New',
    add: 'Add',
    remove: 'Remove',
    update: 'Change',
    latitude: 'Latitude',
    longitude: 'Longitude',
    designer: 'Designer',
    copy: 'Copy',
    copied: 'Copied',
    finishDate: 'End Date',
    color: 'Color',
    code: 'Code',
    classify: 'Classifiy',
    createdAtDate: 'Created at {{date}}',
    todayRelativeDays_zero: 'Today',
    todayRelativeDays_one: '{{count}} day',
    todayRelativeDays_other: '{{count}} days',
    duration: 'Duration',
    viewOnly: 'View only',
    closeTemporarily: 'Close temporarily',
    lastLogin: 'Last Login',
    sendEmail: 'Send Email',
    sendMailInternal: 'With Inspcto',
    sendMailExternal: 'With local E-Mail client',
    secondary_email: 'Secondary email address',
    primary_phone: 'Primary phone number',
    secondary_phone: 'Secondary phone number',
    changeAssignments: 'Change assignment',
    todayRelativeMinutes: '{{count}} Minutes',
    appointment: 'Site visit',
    otp: 'Security code',
    external_preview: 'Open in new page',
    tab_preview: 'Open in new page',
    contact_name: 'Name/Designation',
    salutation: 'Salutation',
    function: 'Function',
    newAppMessage: 'New system message',
    qualifications: 'Qualifications',
    alternate_email: 'Alternative e-mail address',
    disabled: 'Deactivated',
    state: 'Status',
    globalTaskSearch: 'Search orders...',
  },
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm:ss',
    time: 'H:mm:ss',
    timeWithoutSeconds: 'H:mm',
    dayMonth: 'dd.MM',
    dateTimeWithoutSeconds: 'dd.MM.yyyy HH:mm',
    monthYear: 'MMMM yyyy',
  },
  validators: {
    required: ':field is required!',
  },
  navigation: {
    sections: {
      clients: 'Clients',
      tasks: 'Tasks',
      account: 'User Account',
      platform: 'Platform',
      settings: 'Settings',
      templateSettings: 'Templates',
      serviceCatalog: 'Service Catalogue',
      extendedSettings: 'Extended',
      chat: 'Chat',
      debug: 'Debug',
      policyHolders: 'Policyholder',
    },
    items: {
      dashboard: 'Dashboard',
      clients: 'Clients',
      myTasks: 'ToDos',
      tasks: 'Tasks',
      calendar: 'Calendar',
      invoices: 'Invoices',
      payouts: 'Payouts',
      serviceCatalogServices: 'Services',
      serviceCatalogPostcodeFactors: 'Location Factors',
      tradesStatus: 'Trades Status',
      reportingTemplates: 'Reporting questions',
      statisticsTemplates: 'Statistics questions',
      emailTemplates: 'Email templates',
      reports: 'Report templates',
      subtasks: 'ToDos',
      users: 'Users',
      newTask: 'New task',
      pdfs: 'PDF Forms',
      articles: 'Articles',
      map: 'Allocation Areas',
      platform: 'Platform',
      profile: 'Profile',
      branding: 'App settings',
      folderTemplates: 'Default Folder',
      profileSettings: 'Profile Settings',
      logout: 'Logout',
      classificationTags: 'Task Classifications',
      fileClassificationTags: 'File Classifications',
      activityLog: 'Activity Log',
      demoData: 'Demo-Data',
      overview: 'Overview',
      stampTemplates: 'Stamp',
      qualifications: 'Qualifications',
      devices: 'Devices',
      policyHolders: 'Policyholder',
      addressBook: 'Address book',
    },
  },
  components: {
    maskedTextInput: {
      maskValidationMessage: 'Input doesnt satisfy format {{mask}} - check info.',
      info: {
        mask_0: '<bold>0</bold> - Requires a digit (0-9).',
        mask_9: '<bold>9</bold> - Requires a digit (0-9) or a space.',
        title: 'Mask Input Info',
        'mask_#': '<bold>#</bold> - Requires a digit (0-9), space, plus (+), or minus (-) sign.',
        mask_L: '<bold>L</bold> - Requires a letter (a-Z).',
        'mask_?': '<bold>?</bold> - Requires a letter (a-Z) or a space.',
        mask_A: '<bold>A</bold> - Requires an alphanumeric (0-9, a-Z).',
        mask_a: '<bold>a</bold> - Requires an alphanumeric (0-9, a-Z) or a space.',
        'mask_&': '<bold>&</bold> - Requires a character (excluding space).',
        mask_C: '<bold>C</bold> - Requires a character or a space.',
        escape:
          'To use any of the above characters as a literal in the mask, you must escape it with a backslash (\\). For example, the format: "AX-123-456" requires a mask like that: <bold>\\</bold>AX-000-000"',
      },
    },
    phoneInput: {
      invalid: 'Please enter a valid phone number',
      hint: 'Number needs to be entered in international format with a leading +',
    },
    calendarToolbar: {
      today: 'Today',
      timeline: 'Timeline',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      agenda: 'Agenda',
      createEvent: 'create appointment',
    },
    calendar: {
      appointment: 'Appointment',
      note: 'You can enter a new appointment here',
      start: 'Start',
      end: 'End',
      title: 'Title',
      description: 'Description',
      isAllDay: 'All day',
    },
    layout: {
      pageHeader: {
        unread: 'Unread',
        createdAt: 'before {{time}}',
        markAsRead: {
          error: 'Notification cannot be marked as read',
          success: 'Notification marked as read',
        },
        markAllAsRead: {
          title: 'Mark all as read',
          error: 'Notifications cannot be marked as read',
          success: 'Mark all notifications as read',
        },
      },
      historyPopup: {
        lastVisitedPages: 'Last visited pages',
        deleteHistory: 'Delete history',
      },
    },
    footer: {
      termsOfService: 'terms of use',
      privacy: 'Privacy Policy',
      contact: 'Contact',
      imprint: 'Legal Notice',
    },
    category: {
      category: 'Category',
      index: 'Index',
      addNewCategory: 'Add new category',
      validation: 'Please add at least one category',
    },
    changeUser: {
      popover: {
        title: 'Change User',
        description: 'Select a user you want to change to',
        placeholder: 'Search for user...',
      },
      confirm: {
        title: 'Switch user',
        description: 'Do you really want to switch to {{name}}?',
      },
      overlay: {
        title: 'Change user',
        description: 'The page will be loaded with the new user',
      },
      button: {
        backToPreviousUser: 'Back',
        switchUser: 'Switch user',
      },
      quickSelect: {
        title: 'Quick Select',
      },
    },
    strength_bar: {
      security: 'Security',
      strength_1: 'Very weak',
      strength_2: 'weak',
      strength_3: 'medium',
      strength_4: 'good',
      strength_5: 'very good',
    },
    filterDialog: {
      title: 'Advanced filters',
      note: 'Here you can configure more complex filters for your tasks.',
      tabs: {
        common: 'Standard',
        advanced: 'Expert mode',
      },
    },
    filterBar: {
      additional_filters: 'More filters({{num_filters}})',
    },
    filter: {
      popoverTitle: 'Show only',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
    },
    maps: {
      mapSettings: {
        titleMapStyle: 'Map display',
        monochrome: 'Monochrome',
        satellite: 'Sattellite',
        streets: 'Colorful',
      },
    },
    customUpload: {
      uploadFailed: 'Upload failed',
      uploading: 'Uploading …',
      uploadSuccessful: 'Upload successful',
      validationErrors: {
        invalidMaxFileSize: 'File too big. Max {{maxFileSize}} allowed.',
        invalidFileExtension: 'Invalid file extension',
      },
      uploadFile: 'Upload file',
    },
    inputs: {
      switch: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    downloads: {
      title: 'Downloads',
      filesInGeneration_one: '{{count}} File is prepared.',
      filesInGeneration_other: '{{count}} Files are prepared.',
    },
    remainingCharacterIndicator: {
      info: '{{remaining}} of {{max}} characters remaining',
    },
    uploadDropzone: {
      title: 'File upload: {{uploaded}}/{{total}}',
      errors: {
        fileTooLarge: 'File is too large. Maximum {{maxFileSize}} allowed.',
        fileInvalidType: 'Invalid file format. Allowed are: {{allowedFileTypes}}.',
      },
    },
  },
  strength_bar: {
    security: 'Security',
    strength_1: 'Very weak',
    strength_2: 'Weak',
    strength_3: 'Average',
    strength_4: 'Good',
    strength_5: 'Very good',
  },
  filterDialog: {
    title: 'Advanced Filters',
    note: 'Here you can configure more complex filters for your tasks.',
    tabs: {
      common: 'Standard',
      advanced: 'Expert mode',
    },
  },
  filterBar: {
    additional_filters: 'Additional filters({{num_filters}})',
  },
  filter: {
    popoverTitle: 'Show only',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
  },
  maps: {
    mapSettings: {
      titleMapStyle: 'map style',
      monochrome: 'Monochrome',
      satellite: 'Sattellite',
      streets: 'Coloured',
    },
  },
  customUpload: {
    uploadFailed: 'Upload failed',
    uploading: 'Upload...',
    uploadSuccessful: 'Upload successful',
    validationErrors: {
      invalidFileExtension: 'Invalid file extension',
    },
  },
  inputs: {
    switch: {
      active: 'Active',
      inactive: 'Inactive',
    },
  },
  dialogs: {
    formNavigationBlocker: {
      title: 'Leave page?',
      note: 'Do you want to save or discard the changes?',
    },
    discard: {
      title: 'Unsaved changes',
      description: 'Do you want to save or discard the changes?',
    },
    warning: {
      title: 'Warning',
      description: 'Do you want to continue with the action?',
    },
    export: {
      labels: {
        xlsx: 'Export XLSX',
      },
      success: 'Successfully exported.',
      error: 'Error during export.',
    },
  },
  pages: {
    not_found: {
      error: '404 error',
      title: 'Page not found',
      note: 'The page you requested was not found',
      back_to_home: 'To home page',
      back: 'Back',
      refresh: 'Refresh',
    },
    loading: {
      title: 'Application loading ...',
      note: 'Application data is loading.\nPlease be patient',
    },
  },
  errors: {
    generic: 'Something went wrong',
    transcribe: 'Transcribe failed',
  },
  transcribing: {
    message: 'Audiorecording has been started...',
  },
  pdf: {
    stamps: {
      approved: {
        title: 'Approved',
        subtitle: 'by {{name}}',
      },
    },
    documentLoading: 'Document is loading…',
    documentError: 'Error while loading the document',
  },
  notificationChannels: {
    internal: 'Internal',
    email: 'Email',
    sms: 'SMS',
    push: 'Push message',
    emailTemplate: {
      label: 'Email Template',
      defaultItem: 'Standard',
    },
  },
  notificationGroups: {
    clients: {
      title: 'User notifications',
      description: 'Select to specify how deposited users should be notified of actions.',
    },
    tasks: {
      title: 'Task notifications',
      description: 'Select to specify how deposited users should be notified of actions.',
    },
  },
  client_types: {
    policy_holder: 'Policy Holder',
  },
  client_customer_state: {
    active: 'Active',
    offer: 'Offer',
    cancellation: 'Cancellation',
  },
  logoutTimer: {
    message: 'You will be logged out in {{time}} seconds you will be logged out automatically.',
  },
};
