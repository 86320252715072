import {
  TaskStatisticSortAndFilterColumns,
  TaskStatisticsSlotPaginator,
} from '@generated/graphql.generated';
import { useStatisticSlotAbility } from '@module/casl/abilities/StatisticSlotAbility';
import {
  ActionItem,
  GRID_ROW_SPACING_X,
  GridActionsButton,
  initialGridState,
  Loader,
  useConfirmDialogs,
  useGridPagerSettings,
  useGridStateWithPaginationReset,
  useTdElementProps,
} from '@module/common';
import { RefreshButton } from '@module/layout';
import { useDialogs } from '@module/shared/dialogs';
import {
  FilterContextProvider,
  FilterField,
  TextInputFilter,
  ToolbarFilterSearchBar,
  useFilters,
} from '@module/shared/filters';
import { useGridFilter, useGridPagination, useGridSort } from '@module/shared/graphql';
import { isTruthy } from '@module/shared/helpers';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { CreateStatisticSlotDialog } from '@module/statistic-slots/dialogs/CreateStatisticSlotDialog';
import { UpdateStatisticSlotDialog } from '@module/statistic-slots/dialogs/UpdateStatisticSlotDialog';
import { useDeleteTaskStatisticsSlotMutation } from '@module/statistic-slots/graphql/generated/deleteTaskStatisticsSlot.generated';
import {
  TaskStatisticsSlotsQuery,
  useTaskStatisticsSlotsQuery,
} from '@module/statistic-slots/graphql/generated/taskStatisticsSlots.generated';
import { Button, ToolbarItem, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const mapColumns = {
  title: TaskStatisticSortAndFilterColumns.TITLE,
};

interface StatisticSlotCellProps extends GridCellProps {
  dataItem: TaskStatisticsSlotsQuery['taskStatisticsSlots']['data'][number];
}

const CommandCell = (props: StatisticSlotCellProps) => {
  const { dataItem } = props;
  const tdElementProps = useTdElementProps(props);
  const confirm = useConfirmDialogs();

  const { t } = useTranslation();
  const { showDialog } = useDialogs();
  const { showNotification } = useNotifications();

  const [statisticSlotAbility, statisticSlotSubject] = useStatisticSlotAbility();
  const [, deleteStatisticSlot] = useDeleteTaskStatisticsSlotMutation();

  const handleDelete = () => {
    confirm.delete({
      title: t('statisticSlots.dialogs.deleteStatisticSlot.title'),
      description: t('statisticSlots.dialogs.deleteStatisticSlot.description'),
      delete: async () => {
        const mutationResult = await deleteStatisticSlot(
          { id: dataItem.id },
          {
            additionalTypenames: [
              'TaskStatisticsSlotPaginator' satisfies NonNullable<
                TaskStatisticsSlotPaginator['__typename']
              >,
            ],
          },
        );

        if (mutationResult.error || !mutationResult.data?.deleteTaskStatisticsSlot) {
          showNotification(t('common.errors.generic'), NotificationType.Error);
        } else {
          showNotification(
            t('statisticSlots.notifications.statisticSlotDeleted'),
            NotificationType.Success,
          );
        }
      },
    });
  };

  const items: Array<ActionItem> = [
    statisticSlotAbility.can('updateStatisticSlot', statisticSlotSubject()) && {
      actionType: 'edit',
      actionName: t('common.labels.edit'),
      iconClass: 'l-i-pencil',
      handler: () => showDialog({ statisticSlot: dataItem }, UpdateStatisticSlotDialog),
    },
    statisticSlotAbility.can('deleteStatisticSlot', statisticSlotSubject()) && {
      actionType: 'delete',
      actionName: t('common.labels.delete'),
      iconClass: 'l-i-trash-2',
      handler: handleDelete,
    },
  ].filter(isTruthy);

  return (
    <td {...tdElementProps}>
      <GridActionsButton actions={items} />
    </td>
  );
};

export const StatisticSlotsGrid = () => {
  const { t } = useTranslation();
  const [statisticSlotAbility, statisticSlotSubject] = useStatisticSlotAbility();
  const { showDialog } = useDialogs();

  const searchFields = useMemo<Array<FilterField>>(
    () => [
      {
        name: 'title',
        label: t('common.labels.name'),
        filter: TextInputFilter,
        operator: 'contains',
      },
    ],
    [t],
  );
  const filterContext = useFilters(searchFields);

  const pagerSettings = useGridPagerSettings();
  const [gridState, setGridState] = useGridStateWithPaginationReset(
    filterContext.filterState.filter,
    { ...initialGridState, sort: [{ field: 'title', dir: 'asc' }] },
  );

  const gridFilter = useGridFilter(filterContext.filterState.filter, mapColumns);
  const gridSort = useGridSort(gridState.sort, mapColumns);
  const gridPagination = useGridPagination(gridState);

  const [{ data, fetching }, refetch] = useTaskStatisticsSlotsQuery({
    variables: {
      filter: gridFilter,
      sort: {
        columns: gridSort,
      },
      pagination: gridPagination,
    },
  });

  const statisticSlots = data?.taskStatisticsSlots.data ?? [];
  const total = data?.taskStatisticsSlots.paginatorInfo.total ?? 0;

  return (
    <FilterContextProvider filterContext={filterContext}>
      {fetching && <Loader />}

      <Grid
        {...gridState}
        className="!k-border-0"
        pageable={pagerSettings}
        sortable={true}
        onDataStateChange={({ dataState }) => setGridState(dataState)}
        total={total}
        data={statisticSlots}
      >
        <GridToolbar>
          <ToolbarFilterSearchBar />
          <ToolbarSpacer />
          <ToolbarItem>
            <RefreshButton onClick={() => refetch({ requestPolicy: 'network-only' })} onlyIcon />
          </ToolbarItem>

          {statisticSlotAbility.can('createStatisticSlot', statisticSlotSubject()) && (
            <ToolbarItem>
              <Button
                iconClass="l-i-plus"
                size="large"
                themeColor="primary"
                onClick={() => showDialog({}, CreateStatisticSlotDialog)}
              >
                {t('statisticSlots.pages.overview.createSlot')}
              </Button>
            </ToolbarItem>
          )}
        </GridToolbar>

        <GridColumn field="title" title={t('statisticSlots.labels.title')} sortable={true} />
        <GridColumn
          field="identifier"
          title={t('statisticSlots.labels.identifier')}
          sortable={false}
        />
        <GridColumn
          title={t('common.labels.actions')}
          width={64 + GRID_ROW_SPACING_X}
          sortable={false}
          cell={CommandCell}
        />
      </Grid>
    </FilterContextProvider>
  );
};
