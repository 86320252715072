export default {
  labels: {
    title: 'Name',
    identifier: 'Identifier',
  },
  pages: {
    overview: {
      title: 'Globale Statistik Fragen',
      description:
        'Globale Statistik Fragen können verwendet werden, um das die allgemeinen Statistik-Fragen zu erweitern. Sie können eine Frage aus dem Formular mit einer Globalen Statistik Frage verknüpfen. Die Antwort auf die Frage wird dann auch in der Statistik angezeigt.',
      createSlot: 'Frage erstellen',
    },
  },
  dialogs: {
    createStatisticSlot: {
      title: 'Globale Statistik Frage erstellen',
    },
    updateStatisticSlot: {
      title: 'Globale Statistik Frage bearbeiten',
    },
    deleteStatisticSlot: {
      title: 'Globale Statistik Frage löschen',
      description: 'Möchten Sie die Globale Statistik Frage wirklich löschen?',
    },
  },
  notifications: {
    statisticSlotCreated: 'Statistik Frage wurde erstellt',
    statisticSlotUpdated: 'Statistik Frage wurde aktualisiert',
    statisticSlotDeleted: 'Statistik Frage wurde gelöscht',
  },
};
