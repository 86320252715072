import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { ClientLocationFieldsFragmentDoc } from './client_types.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateClientLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.ClientLocationInput;
}>;

export type UpdateClientLocationMutation = {
  __typename?: 'Mutation';
  updateClientLocation?: {
    __typename?: 'ClientLocation';
    id: string;
    client_location_type_id: Types.ClientLocationTypeId;
    primary?: boolean | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    country_id?: string | null;
    street_full?: string | null;
    street?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    notes?: string | null;
    client_id: string;
    different_policy_holder?: string | null;
    different_insurance_policy_number?: string | null;
    name?: string | null;
    country: { __typename?: 'Country'; title: string };
    client_location_type: { __typename?: 'ClientLocationType'; title: string };
  } | null;
};

export const UpdateClientLocationDocument = gql`
  mutation UpdateClientLocation($id: ID!, $input: ClientLocationInput!) {
    updateClientLocation(id: $id, input: $input) {
      ...ClientLocationFields
    }
  }
  ${ClientLocationFieldsFragmentDoc}
`;

export function useUpdateClientLocationMutation() {
  return Urql.useMutation<UpdateClientLocationMutation, UpdateClientLocationMutationVariables>(
    UpdateClientLocationDocument,
  );
}
