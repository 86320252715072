import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskStatisticsSlotMutationVariables = Types.Exact<{
  input: Types.CreateTasksStatisticsSlot;
}>;

export type CreateTaskStatisticsSlotMutation = {
  __typename?: 'Mutation';
  createTaskStatisticsSlot: { __typename?: 'TasksStatisticsSlot'; id: string };
};

export const CreateTaskStatisticsSlotDocument = gql`
  mutation CreateTaskStatisticsSlot($input: CreateTasksStatisticsSlot!) {
    createTaskStatisticsSlot(input: $input) {
      id
    }
  }
`;

export function useCreateTaskStatisticsSlotMutation() {
  return Urql.useMutation<
    CreateTaskStatisticsSlotMutation,
    CreateTaskStatisticsSlotMutationVariables
  >(CreateTaskStatisticsSlotDocument);
}
