export default {
  labels: {
    leader_client_id: 'Abteilung',
    leaderPayoutAmount: 'Auszahlungsbetrag der Abteilung',
    leaderPayoutPercentage: 'Auszahlungsprozentsatz der Abteilung',
  },
  components: {
    clientDetailCommonTab: {
      blocks: {
        qualifications: {
          description: 'Hier können Sie die Qualifikationen des Experten bearbeiten.',
        },
      },
    },
    hiddenInsurancesTab: {
      header: {
        description: 'Hier können Sie den Experten für Versicherungen sperren',
      },
    },
  },
  pages: {
    clientOverview: {
      description: 'Hier sehen Sie eine Liste von Experten, Abteilungen und Versicherungen.',
    },
  },
};
