import { PageBreadcrumb, PageContent, PageSubHeader, PageTitle } from '@module/common';
import { SettingsLayout } from '@module/private';
import { useTranslation } from 'react-i18next';

import { StatisticSlotsGrid } from './StatisticSlotsGrid';

export const StatistcSlotsOverview = () => {
  const { t } = useTranslation();

  return (
    <SettingsLayout>
      <PageTitle title={t('statisticSlots.pages.overview.title')} />
      <PageBreadcrumb path={location.pathname} text={t('statisticSlots.pages.overview.title')} />
      <PageSubHeader
        title={t('statisticSlots.pages.overview.title')}
        description={t('statisticSlots.pages.overview.description')}
      />
      <PageContent>
        <StatisticSlotsGrid />
      </PageContent>
    </SettingsLayout>
  );
};
