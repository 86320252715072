import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpsertClientQualificationMutationVariables = Types.Exact<{
  client_id: Types.Scalars['ID'];
  input: Types.ClientQualificationInput;
}>;

export type UpsertClientQualificationMutation = {
  __typename?: 'Mutation';
  upsertClientQualification?: {
    __typename?: 'Client';
    client_qualification?: {
      __typename?: 'ClientQualification';
      id: string;
      client_id: string;
      can_finish_tasks: boolean;
    } | null;
  } | null;
};

export const UpsertClientQualificationDocument = gql`
  mutation UpsertClientQualification($client_id: ID!, $input: ClientQualificationInput!) {
    upsertClientQualification(client_id: $client_id, input: $input) {
      client_qualification {
        id
        client_id
        can_finish_tasks
      }
    }
  }
`;

export function useUpsertClientQualificationMutation() {
  return Urql.useMutation<
    UpsertClientQualificationMutation,
    UpsertClientQualificationMutationVariables
  >(UpsertClientQualificationDocument);
}
