import '@module/shared/yup/checkForbiddenPrefix';

import { ROUTER_BASENAME } from '@env';
import { AuthProvider, useAuthContext, useIsAuthenticated } from '@module/auth';
import { MeQueryProvider, useMeQueryContext } from '@module/casl';
import { TaskGroupPage } from '@module/classification-tags/pages/TaskGroups/TaskGroupPage';
import { DownloadsProvider, Loader } from '@module/common';
import { HistoryProvider } from '@module/common/components/history/HistoryContext';
import { IpAllowlistGuard } from '@module/common/components/IpAllowlistGuard';
import { OfflineNotification } from '@module/common/components/notifications/OfflineNotification';
import { UrqlClient } from '@module/graphql';
import InterfaceMessageTemplatesRoutes from '@module/interface-message-templates/InterfaceMessageTemplatesRoutes';
import { ThemeProvider } from '@module/layout';
import { PrivateLayoutOutlet } from '@module/private';
import {
  ForgotPasswordPage,
  ImprintPage,
  InitialLoginPage,
  LoginPage,
  MaintenancePage,
  NotFoundPage,
  ResetPasswordPage,
  VerifyEmailPage,
} from '@module/public';
import { DialogsContainer, DialogsProvider } from '@module/shared/dialogs';
import { isNotNullish } from '@module/shared/helpers';
import { usePrevious } from '@module/shared/hooks';
import { LocaleProvider, LocalizationProvider } from '@module/shared/localization';
import { NotificationsContainer, NotificationsProvider } from '@module/shared/notifications';
import StatisticSlotsRoutes from '@module/statistic-slots/StatisticSlotsRoutes';
import * as Sentry from '@sentry/react';
import { lazy, ReactNode, Suspense, useEffect, useLayoutEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import i18n from './localization';

const ClientsRoutes = lazy(() => import('@module/clients/ClientsRoutes'));
const PolicyHoldersRoutes = lazy(() => import('@module/policy-holders/PolicyHoldersRoutes'));
const TemplateRoutes = lazy(() => import('@module/templates/TemplateRoutes'));
const EmailTemplateRoutes = lazy(() => import('@module/emailtemplates/EmailTemplatesRoutes'));
const FolderTemplatesRoutes = lazy(
  () => import('@module/tasks/pages/FolderTemplatesPage/FolderTemplatesRoutes'),
);
const DashboardRoutes = lazy(() => import('@module/dashboard/DashboardRoutes'));
const AddressBookRoutes = lazy(() => import('@module/addressBook/AddressBookRoutes'));
const SubTasksRoutes = lazy(() => import('@module/tasks/pages/SubTasks/SubTasksRoutes'));
const PlatformRoutes = lazy(() => import('@module/platform/PlatformRoutes'));
const TasksRoutes = lazy(() => import('@module/tasks/TasksRoutes'));
const EventRoutes = lazy(() => import('@module/events/EventsRoutes'));
const ArticleRoutes = lazy(() => import('@module/articles/ArticleRoutes'));
const VideoRoutes = lazy(() => import('@module/video/VideoRoutes'));
const CustomerFormRoutes = lazy(() => import('@module/customer-form/CustomerFormRoutes'));
const AreaAssignmentsRoutes = lazy(() => import('@module/area-assignments/AreaAssignmentsRoutes'));
const ServiceCatalogRoutes = lazy(() => import('@module/service-catalog/ServiceCatalogRoutes'));
const PdfFormRoutes = lazy(() => import('@module/pdf-forms/PdfFormRoutes'));
const ReportLayoutsRoutes = lazy(() => import('@module/report-layouts/ReportLayoutsRoutes'));
const ThemeDevPage = lazy(() => import('./modules/theme-dev/ThemeDevPage'));
const ClassificationTagRoutes = lazy(
  () => import('./modules/classification-tags/ClassificationTagRoutes'),
);
const FileClassificationTagRoutes = lazy(
  () => import('./modules/classification-tags/FileClassificationTagRoutes'),
);
const SubformClassificationTagRoutes = lazy(
  () => import('./modules/classification-tags/SubformClassificationTagRoutes'),
);
const QualitificationRoutes = lazy(() => import('./modules/qualifications/QualificationRoutes'));

const ActivityLogRoutes = lazy(() => import('./modules/activity-log/ActivityLogRoutes'));
const DemoDataRoutes = lazy(() => import('./modules/demo-data/DemoDataRoutes'));
const InvoicesRoutes = lazy(() => import('./modules/invoices/InvoicesRoutes'));
const ProposalsRoutes = lazy(() => import('./modules/proposals/ProposalsRoutes'));

const SubTasksTemplatesRoutes = lazy(
  () => import('./modules/subtaskTemplates/SubTasksTemplatesRoutes'),
);
const StampTemplatesRoutes = lazy(() => import('./modules/stampTemplates/StampTemplatesRoutes'));
const PayoutsRoutes = lazy(() => import('./modules/payouts/PayoutRoutes'));
const SettingsRoutes = lazy(() => import('@module/settings/SettingsRoutes'));
const DevicesRoutes = lazy(() => import('@module/devices/DevicesRoutes'));

/**
 * Scrolls the page up when navigating to a different path.
 *
 * PopStateEvents (back/forward) are excluded to allow browsers to restore
 * scroll positions for existing history entries by themselves.
 *
 * This can probably done better with React Router's <ScrollRestoration />
 * component after switching to the new data API:
 * https://reactrouter.com/en/main/components/scroll-restoration
 */
const ScrollManager = () => {
  const navigationType = useNavigationType();
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  useLayoutEffect(() => {
    if (pathname !== prevPathname && navigationType !== 'POP') {
      document.body.scrollTo(0, 0);
    }
  }, [pathname, prevPathname, navigationType]);

  return null;
};

const MeQueryProviderWithAuth = (props: { children: ReactNode }) => {
  const { authState } = useAuthContext();

  return <MeQueryProvider paused={!authState?.data.access_token} {...props} />;
};

const SyncUserToSentry = () => {
  const meQueryContext = useMeQueryContext();
  const { authStatePrevious } = useAuthContext();
  const role = meQueryContext.data?.me?.role;
  const hasAuthStatePrevious = isNotNullish(authStatePrevious);

  useEffect(() => {
    Sentry.setUser({
      Role: role ? `${role}${hasAuthStatePrevious ? ' (impersonated)' : ''}` : 'PUBLIC',
    });
  }, [role, hasAuthStatePrevious]);

  return null;
};

const NavigateToLoginOrDashboard = () => {
  const isAuthenticated = useIsAuthenticated();

  return <Navigate to={isAuthenticated ? '/dashboard' : '/login'} replace={true} />;
};

const App = () => {
  return (
    <IpAllowlistGuard>
      <AuthProvider>
        <LocaleProvider i18n={i18n}>
          <UrqlClient>
            <MeQueryProviderWithAuth>
              <SyncUserToSentry />

              <ThemeProvider>
                <LocalizationProvider>
                  <BrowserRouter basename={ROUTER_BASENAME}>
                    <ScrollManager />

                    <DialogsProvider>
                      <DownloadsProvider>
                        <OfflineNotification />

                        <NotificationsProvider>
                          <Suspense
                            fallback={
                              <div>
                                <Loader />
                              </div>
                            }
                          >
                            <HistoryProvider>
                              <Routes>
                                <Route index element={<NavigateToLoginOrDashboard />} />
                                <Route
                                  path="/initial-login/:token"
                                  element={<InitialLoginPage />}
                                />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                <Route path="/reset-password" element={<ResetPasswordPage />} />
                                <Route path="/verify/:token" element={<VerifyEmailPage />} />
                                <Route path="/imprint" element={<ImprintPage />} />
                                <Route path="/maintenance" element={<MaintenancePage />} />
                                <Route path="/video/*" element={<VideoRoutes />} />
                                <Route
                                  path="/dev/customer-form/*"
                                  element={<CustomerFormRoutes />}
                                />

                                <Route element={<PrivateLayoutOutlet />}>
                                  <Route path="/dashboard/*" element={<DashboardRoutes />} />
                                  <Route path="/clients/*" element={<ClientsRoutes />} />
                                  <Route
                                    path="/policy-holders/*"
                                    element={<PolicyHoldersRoutes />}
                                  />
                                  <Route path="/tasks/*" element={<TasksRoutes />} />
                                  <Route path="/sub-tasks/*" element={<SubTasksRoutes />} />
                                  <Route path="/events/*" element={<EventRoutes />} />
                                  <Route path="/invoices/*" element={<InvoicesRoutes />} />
                                  <Route path="/proposals/*" element={<ProposalsRoutes />} />
                                  <Route path="/payouts/*" element={<PayoutsRoutes />} />
                                  <Route path="/settings/overview/*" element={<SettingsRoutes />} />
                                  <Route path="/settings/platform/*" element={<PlatformRoutes />} />
                                  <Route
                                    path="/settings/templates/*"
                                    element={<TemplateRoutes />}
                                  />
                                  <Route path="/settings/pdf-forms/*" element={<PdfFormRoutes />} />
                                  <Route
                                    path="/settings/email-templates/*"
                                    element={<EmailTemplateRoutes />}
                                  />
                                  <Route
                                    path="/settings/interface-message-templates/*"
                                    element={<InterfaceMessageTemplatesRoutes />}
                                  />
                                  <Route
                                    path="/settings/report-layouts/*"
                                    element={<ReportLayoutsRoutes />}
                                  />
                                  <Route
                                    path="/settings/sub-tasks-templates/*"
                                    element={<SubTasksTemplatesRoutes />}
                                  />
                                  <Route
                                    path="/settings/stamp-templates/*"
                                    element={<StampTemplatesRoutes />}
                                  />
                                  <Route
                                    path="/settings/service-catalog/*"
                                    element={<ServiceCatalogRoutes />}
                                  />
                                  <Route
                                    path="/settings/area-assignments/*"
                                    element={<AreaAssignmentsRoutes />}
                                  />
                                  <Route path="/settings/articles/*" element={<ArticleRoutes />} />
                                  <Route
                                    path="/settings/folder-templates"
                                    element={<FolderTemplatesRoutes />}
                                  />
                                  <Route
                                    path="/settings/qualifications/*"
                                    element={<QualitificationRoutes />}
                                  />
                                  <Route
                                    path="/settings/classification-tags/*"
                                    element={<ClassificationTagRoutes />}
                                  />
                                  <Route
                                    path="/settings/file-classification-tags/*"
                                    element={<FileClassificationTagRoutes />}
                                  />
                                  <Route
                                    path="/settings/subform-classification-tags/*"
                                    element={<SubformClassificationTagRoutes />}
                                  />
                                  <Route
                                    path="/settings/task-groups/*"
                                    element={<TaskGroupPage />}
                                  />
                                  <Route
                                    path="/settings/activity-log/*"
                                    element={<ActivityLogRoutes />}
                                  />
                                  <Route path="/settings/devices/*" element={<DevicesRoutes />} />
                                  <Route
                                    path="/settings/demo-data/*"
                                    element={<DemoDataRoutes />}
                                  />
                                  <Route
                                    path="/settings/address-book/*"
                                    element={<AddressBookRoutes />}
                                  />
                                  <Route
                                    path="/settings/statistic-slots/*"
                                    element={<StatisticSlotsRoutes />}
                                  />
                                </Route>

                                <Route path="/theme-dev" element={<ThemeDevPage />} />

                                <Route path="*" element={<NotFoundPage />} />
                              </Routes>
                            </HistoryProvider>
                          </Suspense>
                          <DialogsContainer />
                          <NotificationsContainer />
                        </NotificationsProvider>
                      </DownloadsProvider>
                    </DialogsProvider>
                  </BrowserRouter>
                </LocalizationProvider>
              </ThemeProvider>
            </MeQueryProviderWithAuth>
          </UrqlClient>
        </LocaleProvider>
      </AuthProvider>
    </IpAllowlistGuard>
  );
};

export default App;
