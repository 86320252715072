import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskStatisticsSlotsQueryVariables = Types.Exact<{
  filter: Types.TaskStatisticsSlotFilter;
  sort: Types.TaskStatisticsSlotSort;
  pagination: Types.PaginationInput;
}>;

export type TaskStatisticsSlotsQuery = {
  __typename?: 'Query';
  taskStatisticsSlots: {
    __typename?: 'TaskStatisticsSlotPaginator';
    data: Array<{
      __typename?: 'TasksStatisticsSlot';
      id: string;
      identifier: string;
      title: string;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export const TaskStatisticsSlotsDocument = gql`
  query TaskStatisticsSlots(
    $filter: TaskStatisticsSlotFilter!
    $sort: TaskStatisticsSlotSort!
    $pagination: PaginationInput!
  ) {
    taskStatisticsSlots(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        id
        identifier
        title
      }
      paginatorInfo {
        total
      }
    }
  }
`;

export function useTaskStatisticsSlotsQuery(
  options: Omit<Urql.UseQueryArgs<TaskStatisticsSlotsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskStatisticsSlotsQuery, TaskStatisticsSlotsQueryVariables>({
    query: TaskStatisticsSlotsDocument,
    ...options,
  });
}
