import { LoadingButton, TasksStatisticsSlot, TaskStatisticsSlotPaginator } from '@module/common';
import {
  CancelButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogHeaderBar,
  DialogHeaderIcon,
} from '@module/layout';
import { DialogProps, useDialogs } from '@module/shared/dialogs';
import { Form } from '@module/shared/forms';
import { NotificationType, useNotifications } from '@module/shared/notifications';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FormElement } from '@progress/kendo-react-form';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StatisticSlotFormFields,
  StatisticSlotSchema,
  statisticSlotSchema,
  statisticSlotSchemaValidator,
} from '../components/StatisticSlotFormFields';
import { useUpdateTaskStatisticsSlotMutation } from '../graphql/generated/updateTaskStatisticsSlot.generated';

type StatisticSlotSubset = Pick<TasksStatisticsSlot, 'id' | 'title' | 'identifier'>;

export const UpdateStatisticSlotDialog = (
  props: DialogProps & { statisticSlot: StatisticSlotSubset },
) => {
  const { dialogId, statisticSlot } = props;
  const { t } = useTranslation();
  const { hideDialog } = useDialogs();
  const formElementId = useId();
  const { showNotification } = useNotifications();

  const [{ fetching }, updateStatisticSlot] = useUpdateTaskStatisticsSlotMutation();

  const handleHideDialog = () => hideDialog(dialogId);

  const handleSubmit = async (values: unknown) => {
    const validValues = values as StatisticSlotSchema;
    const mutationResult = await updateStatisticSlot(
      {
        input: {
          id: statisticSlot.id,
          ...validValues,
        },
      },
      {
        additionalTypenames: [
          'TaskStatisticsSlotPaginator' satisfies NonNullable<
            TaskStatisticsSlotPaginator['__typename']
          >,
        ],
      },
    );

    if (mutationResult.error) {
      showNotification(t('common.errors.generic'), NotificationType.Error);
    } else {
      showNotification(
        t('statisticSlots.notifications.statisticSlotUpdated'),
        NotificationType.Success,
      );
      handleHideDialog();
    }
  };

  return (
    <Dialog width="small" onClose={handleHideDialog}>
      <DialogHeaderBar>
        <DialogHeaderIcon iconClass="l-i-plus u-text-2xl" color="success" />
        <DialogHeader title={t('statisticSlots.dialogs.updateStatisticSlot.title')} />
      </DialogHeaderBar>

      <DialogContent>
        <Form
          id={formElementId}
          schema={statisticSlotSchema}
          validator={statisticSlotSchemaValidator}
          onSubmit={handleSubmit}
          initialValues={{
            title: statisticSlot.title,
            identifier: statisticSlot.identifier,
          }}
          render={() => (
            <FormElement>
              <StatisticSlotFormFields />
            </FormElement>
          )}
        />
      </DialogContent>

      <DialogActionsBar layout="end">
        <CancelButton onClick={handleHideDialog} />
        <LoadingButton
          type="submit"
          form={formElementId}
          iconClass="l-i-save"
          size="large"
          themeColor="primary"
          isLoading={fetching}
        >
          {t('common.labels.save')}
        </LoadingButton>
      </DialogActionsBar>
    </Dialog>
  );
};
