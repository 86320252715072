import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteTaskStatisticsSlotMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type DeleteTaskStatisticsSlotMutation = {
  __typename?: 'Mutation';
  deleteTaskStatisticsSlot?: { __typename?: 'TasksStatisticsSlot'; id: string } | null;
};

export const DeleteTaskStatisticsSlotDocument = gql`
  mutation DeleteTaskStatisticsSlot($id: ID!) {
    deleteTaskStatisticsSlot(id: $id) {
      id
    }
  }
`;

export function useDeleteTaskStatisticsSlotMutation() {
  return Urql.useMutation<
    DeleteTaskStatisticsSlotMutation,
    DeleteTaskStatisticsSlotMutationVariables
  >(DeleteTaskStatisticsSlotDocument);
}
