import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface StatisticSlotSubject {
  me: CurrentUserAbilityFragment;
}

type StatisticSlotSubjectArg = Partial<StatisticSlotSubject>;

type Actions =
  | 'viewStatisticSlots'
  | 'createStatisticSlot'
  | 'updateStatisticSlot'
  | 'deleteStatisticSlot';

type Subjects = StatisticSlotSubject | 'StatisticSlotSubject';

type StatisticSlotAbility = Ability<[Actions, Subjects]>;
const statisticSlotAbility = Ability as AbilityClass<StatisticSlotAbility>;

export const useStatisticSlotAbility = (): [
  StatisticSlotAbility,
  (sub?: StatisticSlotSubjectArg) => StatisticSlotSubject & ForcedSubject<'StatisticSlotSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(statisticSlotAbility);

    can('viewStatisticSlots', 'StatisticSlotSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_STATISTIC_SLOT_INDEX] },
    });
    can('createStatisticSlot', 'StatisticSlotSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_STATISTIC_SLOT_CREATE] },
    });
    can('updateStatisticSlot', 'StatisticSlotSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_STATISTIC_SLOT_UPDATE] },
    });
    can('deleteStatisticSlot', 'StatisticSlotSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_STATISTIC_SLOT_DELETE] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: StatisticSlotSubjectArg) => {
      return subjectHelper('StatisticSlotSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanStatisticSlotProps = GenericCanSharedProps<Actions>;

export const CanStatisticSlot = (props: CanStatisticSlotProps) => {
  const [statisticSlotAbility, statisticSlotSubject] = useStatisticSlotAbility();

  return (
    <GenericCan<Actions, Subjects, StatisticSlotAbility>
      ability={statisticSlotAbility}
      subject={statisticSlotSubject()}
      {...props}
    />
  );
};
